import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {Stack, Tooltip, Grid2 as Grid, Box, Button, IconButton, Typography, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import {useForm, useFieldArray, useFormContext, FormProvider} from 'react-hook-form';
import * as MaterialIcons from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {v4 as uuid} from 'uuid';

import * as Icons from '-/components/Icons';

import TextField from '-/form/TextField';
import SelectField from '-/form/SelectField';
import CheckboxField from '-/form/CheckboxField';
import AutocompleteField from '-/form/AutocompleteField';
import ImageUploadField from '-/form/ImageUploadField';
import CollectionAutocompleteField from '-/form/CollectionAutocompleteField';

import ResponsiveDialog from '-/components/ResponsiveDialog';

const specialFields = {
    name: /name/i,
    expiryDate: /^expiry/i
};

const FieldsGridField = ({name, disabled, ...rest}) => {
    const {watch, setValue} = useFormContext();
    const {fields, append, move, remove} = useFieldArray({
        name
    });

    const currentValue = watch(name);

    useEffect(() => {
        if (currentValue) {
            let hasChanges = false;

            const newFields = currentValue.map(field => {
                const {id, label} = field;
                const specialField = Object.keys(specialFields).find(key => {
                    return specialFields[key].test(label);
                });

                if (specialField && specialField !== id) {
                    hasChanges = true;

                    return {
                        ...field,
                        id: specialField
                    };
                }

                return field;
            });

            if (hasChanges) {
                setValue(name, newFields);
            }
        }
    }, [currentValue]);

    return (
        <Box {...rest}>
            <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{px: 1, flex: 1}}>Label</TableCell>
                            <TableCell sx={{px: 1, flex: 2}}>Field Type</TableCell>
                            <TableCell sx={{px: 1, width: 50}}>
                                <Tooltip title="Will not allow items to have duplicates of this field"><span>Unique</span></Tooltip>
                            </TableCell>
                            <TableCell sx={{px: 1, width: 135}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.length === 0 && (
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell colSpan={4} align="center">
                                    No fields
                                </TableCell>
                            </TableRow>
                        )}
                        {fields.map((field, index) => {
                            const {label} = currentValue[index] || {};
                            const specialField = Object.keys(specialFields).find(key => {
                                return specialFields[key].test(label);
                            });

                            let helperText;
                            let typeDisabled = false;
                            if (specialField === 'expiryDate') {
                                typeDisabled = true;
                                helperText = 'This field will automatically generate tasks to notify users when the item is about to expire.';
                            }

                            return (
                                <TableRow key={field.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell sx={{pl: 1, pr: 0, py: 1}}>
                                        <TextField
                                            fullWidth
                                            required
                                            size="small"
                                            label={false}
                                            disabled={disabled}
                                            placeholder="Name, Expiry Date, Member, etc."
                                            name={`${name}.${index}.label`}
                                            helperText={helperText}
                                        />
                                    </TableCell>
                                    <TableCell sx={{px: 1, py: 1, verticalAlign: 'top'}}>
                                        <SelectField
                                            fullWidth
                                            label={false}
                                            disabled={typeDisabled || disabled}
                                            name={`${name}.${index}.type`}
                                            size="small"
                                            options={[
                                                {value: 'text', label: 'Text'},
                                                {value: 'boolean', label: 'Yes/No'},
                                                {value: 'date', label: 'Date'},
                                                {value: 'apparatus', label: 'Apparatus'},
                                                {value: 'user', label: 'Member'}
                                            ]}
                                        />
                                    </TableCell>
                                    <TableCell sx={{px: 1, py: 1, verticalAlign: 'top'}}>
                                        <CheckboxField
                                            disabled={disabled}
                                            name={`${name}.${index}.unique`}
                                        />
                                    </TableCell>
                                    <TableCell sx={{pl: 1, pr: 1, py: 1, verticalAlign: 'top'}}>
                                        <Stack direction="row" spacing={1}>
                                            <IconButton disabled={disabled} onClick={() => move(index, Math.max(0, index - 1))}>
                                                <ArrowDropUpIcon />
                                            </IconButton>
                                            <IconButton disabled={disabled} onClick={() => move(index, Math.min(index + 1, fields.length - 1))}>
                                                <ArrowDropDownIcon />
                                            </IconButton>
                                            <IconButton disabled={disabled} onClick={() => remove(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
                <Button
                    onClick={() => append({id: uuid(), type: 'text'})}
                    startIcon={<AddIcon />}
                    disabled={disabled}
                >
                    Add Field
                </Button>
            </Box>
        </Box>
    );
};

export default function CategoryDialog({onSubmit: onUpdate, onDelete, onArchive, parent, item, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: useMemo(() => {
            return item || {
                name: '',
                icon: '',
                imageUrl: '',
                imageThumbnailUrl: '',
                additionalFields: [],
                apparatus: '',
                archived: false
            };
        }, [item]),
        mode: 'onChange'
    });
    const {reset, handleSubmit, watch} = methods;
    const archived = watch('archived');

    useEffect(() => {
        reset(item || {
            name: '',
            icon: '',
            imageUrl: '',
            imageThumbnailUrl: '',
            additionalFields: [],
            apparatus: '',
            archived: false
        });
    }, [item, reset]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {icon, additionalFields: rawAdditionalFields, ...rest} = data;

        const additionalFields = rawAdditionalFields.map(field => {
            const {id, label} = field;
            const specialField = Object.keys(specialFields).find(key => {
                return specialFields[key].test(label);
            });

            return {
                ...field,
                id: specialField || id
            };
        });

        const result = await onUpdate({
            ...rest,
            additionalFields,
            icon: icon && icon.value
        });

        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    }, [handleClose, reset, onUpdate]);

    const handleDelete = async () => {
        setLoading();

        const result = await onDelete(item);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    };

    const handleArchive = async () => {
        setLoading();

        await onArchive(item);

        setLoading(false);
    };

    const icons = [
        ...Object.keys(Icons).map(icon => {
            return {
                value: icon,
                label: icon
            };
        }),
        ...Object.keys(MaterialIcons).map(icon => {
            return {
                value: icon,
                label: icon
            };
        })
    ];

    const type = parent ? 'Subcategory' : 'Gear Category';

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                PaperProps={{
                    component: 'form',
                    noValidate: true,
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>{item ? `Update ${type}` : `Add New ${type}`}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {item && (
                            <Grid size={{xs: 12, sm: 6, md: 4}}>
                                <ImageUploadField name="image" disabled={loading || archived} />
                            </Grid>
                        )}

                        <Grid size={{xs: 12, sm: item ? 6 : 12, md: item ? 8 : 12}}>
                            <TextField
                                sx={{mt: 1}}
                                required
                                name="name"
                                label="Name"
                                placeholder={parent ? 'Bottles, Packs, etc.' : 'SCBA, Ladders, etc.'}
                                fullWidth
                                disabled={loading || archived}
                            />
                            <AutocompleteField
                                sx={{mt: 2}}
                                name="icon"
                                label="Icon"
                                options={icons}
                                multiple={false}
                                fullWidth
                                disabled={loading || archived}
                                renderOption={(props, option) => {
                                    const {key, ...rest} = props;
                                    const {value, label} = option;
                                    const Icon = (Icons || {})[value] || MaterialIcons[value];
                                    
                                    return (
                                        <li key={key} {...rest}>
                                            <Box display={'flex'} flexDirection={'row'}>
                                                <Icon /> {label}
                                            </Box>
                                        </li>
                                    );
                                }}
                            />
                            <CollectionAutocompleteField
                                sx={{mt: 2}}
                                name="apparatus"
                                label="Apparatus"
                                collection="apparatus"
                                filterArchived
                                displayProperty="tag"
                                multiple={false}
                                disabled={loading || archived}
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{mt: 2}}>Fields</Typography>
                    <Typography variant="body2" color="text.secondary">
                        Add the fields for items within this category. Some examples are: <strong>name</strong>, <strong>expiry date</strong>, <strong>member</strong>, <strong>apparatus</strong> and <strong>quantity</strong>. Fields named <strong>expiry</strong> or <strong>expiry date</strong> will automatically generate tasks to notify users when the item is about to expire. The first field will be used as the displayed name for the item in emails and reports.
                    </Typography>
                    <FieldsGridField name="additionalFields" sx={{mt: 2}} disabled={loading || archived} />
                </DialogContent>
                <DialogActions>
                    {(item && (onDelete || onArchive)) && (
                        <>
                            {onDelete && <Button onClick={handleDelete} disabled={loading}>Delete</Button>}
                            {onArchive && <Button onClick={handleArchive} disabled={loading}>{archived ? 'Unarchive' : 'Archive'}</Button>}
                            <Box sx={{flex: 1}} />
                        </>
                    )}

                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button variant="contained" type="submit" loading={loading} disabled={loading || archived}>{item ? 'Update' : 'Add'} {type}</Button>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};