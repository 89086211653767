import React, {useMemo, useEffect, useState, useCallback, useContext} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Box, Stack, Typography, Divider, Skeleton, FormControlLabel, Checkbox} from '@mui/material';
import moment from 'moment';
import {GridActionsCellItem, useGridApiRef} from '@mui/x-data-grid-pro';
import {get, omit, capitalize, startCase} from 'lodash';
import {doc, collection, onSnapshot, addDoc, query, updateDoc, limit, orderBy, where} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {RecordTypes} from '@embertracking/common';
import CheckIcon from '@mui/icons-material/Check';
import ChecklistIcon from '@mui/icons-material/Checklist';

import {UserContext} from '-/contexts/User';

import {uploadImage, hasPermission, processRawDoc, processRawDocs, getCollection, ensureJSDates} from '-/data/utils';

import SearchableDataGrid from '-/components/SearchableDataGrid';

import {db} from '-/firebase';

import ItemDialog from './ItemDialog';
import RefillDialog from './RefillDialog';

const LastRecordCell = React.memo(({lastRecord, path}) => {
    const [loading, setLoading] = useState(!lastRecord);
    const [record, setRecord] = useState(lastRecord);
  
    useEffect(() => {
        if (lastRecord || !path) {
            return;
        }

        setLoading(true);
  
        const ref = query(collection(db, path, 'records'), orderBy('createdAt', 'desc'), limit(1));
        const unsubscribe = onSnapshot(
            ref,
            snapshot => {
                const records = processRawDocs(snapshot);
                setRecord(records[0]);
                setLoading(false);
            },
            error => {
                console.warn('LastRecordCell error:', error);
                setLoading(false);
            }
        );
  
        return () => unsubscribe();
    }, [path, lastRecord]);
  
    const {createdAt, type: typeRaw} = record || {};
    let label = record ? RecordTypes[typeRaw] || 'Unknown' : '';
    if (typeRaw === 'CHECK') {
        console.log(record)
        const checkType = get(record, 'check.type');
        label = checkType ? `${startCase(checkType)} Check` : 'Check';
    }
  
    return (
        <Stack direction="column" alignItems="flex-start" spacing={0}>
            <Typography variant="body2">
                {loading ? <Skeleton sx={{minWidth: 80}} /> : label}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{fontSize: '0.7rem'}}>
                {loading ? <Skeleton sx={{minWidth: 110}} /> : record && createdAt ? createdAt.fromNow() : ''}
            </Typography>
        </Stack>
    );
});

export const useItems = props => {
    const {includeArchived = false} = props || {};

    const params = useParams();
    const [loadingItems, setLoadingItems] = useState(false);
    const [loadingParent, setLoadingParent] = useState(false);
    const [items, setItems] = useState([]);
    const [parent, setParent] = useState(null);
    const {users} = useContext(UserContext);

    const {'*': url} = params;
    const isItemPage = /gear\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.test(url);

    const parentRefArgs = useMemo(() => {
        const {id: uid, ...otherParams} = params;
        const refArgs = [db];

        Object.entries(otherParams).forEach(([key, param]) => {
            if (key !== '*' && param) refArgs.push('gear', param);
        });

        if (!isItemPage) {
            refArgs.push('gear', uid);
        }

        return refArgs;
    }, [params, isItemPage]);

    const refArgs = useMemo(() => [...parentRefArgs, 'items'], [parentRefArgs]);
    const apparatusPromise = useMemo(() => getCollection(db, 'apparatus'), []);

    const processItem = useCallback((row, additionalFields, apparatus) => {
        const {uid} = row;
        const path = [...refArgs.slice(1), uid].join('/');
        const result = {...row, path};
    
        Object.keys(row).forEach(field => {
            const value = row[field];
            const {type = 'text'} = additionalFields.find(f => f.id === field) || {};
            if (type === 'user') {
                result[field] = users.find(user => user.uid === value);
            } else if (type === 'apparatus') {
                result[field] = apparatus.find(item => item.uid === value);
            }
        });
    
        return result;
    }, [refArgs, users]);

    useEffect(() => {
        setLoadingItems(true);

        let ref = collection(...refArgs);
        if (!includeArchived) {
            ref = query(ref, where('archived', '!=', true));
        }

        const unsubscribe = onSnapshot(
            ref,
            async snapshot => {
                const apparatus = await apparatusPromise;
                const {additionalFields = []} = parent || {};
                const rows = processRawDocs(snapshot);

                setItems(rows.map(row => processItem(row, additionalFields, apparatus)));

                setLoadingItems(false);
            },
            error => {
                console.warn('Items snapshot error:', error);
                setLoadingItems(false);
            }
        );
        return () => unsubscribe();
    }, [refArgs, parent, processItem, includeArchived]);

    useEffect(() => {
        setLoadingParent(true);

        const ref = doc(...parentRefArgs);
        const unsubscribe = onSnapshot(
            ref,
            snapshot => {
                setParent(processRawDoc(snapshot));

                setLoadingParent(false);
            },
            error => {
                console.warn('Parent snapshot error:', error);

                setLoadingParent(false);
            }
        );
        return () => unsubscribe();
    }, [parentRefArgs]);

    const loading = loadingItems || loadingParent;

    return {
        items,
        parent,
        loading,
        refArgs,
        parentRefArgs
    };
};

export default function ItemsGrid(props) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [refillOpen, setRefillOpen] = useState(false);
    const [includeArchived, setIncludeArchived] = useState(false);
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const params = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const {uid: userUid} = currentUser;
    const apiRef = useGridApiRef();
    const {items, parent, loading, refArgs} = useItems({includeArchived});

    const {uid: parentUid, name, checks: checkTypes, additionalFields = []} = parent || {};
    const isCylinder = !!(name || '').match(/cylinder|bottle/i);

    const autosizeOptions = {
        columns: [
            ...additionalFields.map(field => field.id)
        ],
        includeOutliers: true,
        includeHeaders: true,
        expand: true
    };

    const rows = (items || []).map(row => {
        const result = {...row};

        for (const field of additionalFields) {
            const {id, type} = field;
            let value = row[id];

            if (type === 'user') {
                value = value ? value.fullName : '-';
            } else if (type === 'apparatus') {
                value = value ? value.tag : '-';
            }

            result[id] = value;
        }

        return result;
    });

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            if (apiRef?.current) {
                apiRef.current.autosizeColumns(autosizeOptions);
            }
        }, 100);
    
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [rows, apiRef, autosizeOptions]);

    const baseUrl = useMemo(() => {
        let parts = [];

        Object.entries(params).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        return `/${parts.join('/')}`;
    }, [params]);

    const onRowClick = useCallback((params, e) => {
        const {row} = params;
        const {uid} = row;
        const {metaKey} = e;

        const url = `${baseUrl}/items/${uid}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [baseUrl, navigate]);

    const onAddRefillRecord = useCallback(async data => {
        const {uid} = refillOpen;

        try {
            const args = [...refArgs, uid, 'records'];
            const ref = collection(...args);
            const {users = [], ...rest} = data;

            const record = ensureJSDates({
                ...rest,
                createdAt: new Date(),
                users: users.map(user => user.uid || user)
            });

            await updateDoc(doc(...[...refArgs, uid]), {
                lastRefill: new Date()
            });
            
            await addDoc(ref, record);
            return record;
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [enqueueSnackbar, refArgs, refillOpen]);

    const handleAdd = useCallback(() => {
        setOpen(true);
    }, []);

    const handleStartCheck = useCallback(async (type, itemUid) => {
        console.log({type, itemUid});

        // if (activeChecks.length) {
        //     const [record] = activeChecks;
        //     return navigate(`/apparatus/${uid}/${type}/${record.uid}`);
        // }

        try {
            const ref = collection(db, 'checks');
            const q = query(ref, where('gear', '==', parentUid), where('type', '==', type), orderBy('createdAt', 'desc'), limit(1));
            const docs = await getCollection(db, q);

            const [record] = docs || [];
            if (!record) {
                enqueueSnackbar('Checklist not found. Please contact the administrator.', {variant: 'error'});
                return;
            }

            const {sections = [], checks = []} = record;

            const data = {
                type,
                loggedChecks: sections.length ? sections : [{checks}],
                createdAt: new Date(),
                active: true,
                users: []
            };
            
            const checkRef = collection(...refArgs, itemUid, 'checks');
            const checkRecordRaw = await addDoc(checkRef, data);

            navigate(`items/${itemUid}/${type}/${checkRecordRaw.id}`);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        // navigate(`${itemUid}/${type}`);
    }, [parentUid]);

    const onAdd = useCallback(async data => {
        try {
            const {imageFile, ...rest} = data;
            
            const ref = collection(...refArgs);
            const record = ensureJSDates({
                ...omit(rest, ['uid', 'id']),
                createdAt: new Date(),
                user: userUid
            });

            const {id: uid} = await addDoc(ref, record);

            const path = [...refArgs.slice(1), uid].join('/');
            
            if (imageFile) {
                await Promise.all(imageFile.map(async file => {
                    return await uploadImage(path, file, true);
                }));
            }

            return {
                id: uid,
                uid,
                record
            };
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [enqueueSnackbar, refArgs, userUid]);

    const handleRefill = useCallback(row => {
        setRefillOpen(row);
    }, []);

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Created',
            sortable: true,
            minWidth: 120,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '-';
            }
        },
        ...additionalFields.map(field => {
            const {id, label, type} = field;
            let headerName = label;

            if (!headerName) {
                if (type === 'apparatus') {
                    headerName = 'Apparatus';
                } else if (type === 'date') {
                    headerName = 'Date';
                } else if (type === 'user') {
                    headerName = 'Member';
                }
            }

            return {
                field: id,
                headerName: headerName || '',
                sortable: true,
                minWidth: 180,
                valueFormatter: value => {
                    if (type === 'date' || moment.isMoment(value)) {
                        return value ? moment(value).format(dateFormat) : '-';
                    }

                    return value ? value : '-';
                },
                renderCell: params => {
                    const {value, formattedValue} = params;

                    if (type === 'boolean' && value === true) {
                        return value ? <CheckIcon /> : null;
                    }

                    return formattedValue;
                }
            };
        }),
        {
            field: 'lastRecord',
            headerName: 'Last Record',
            minWidth: 180,
            type: 'date',
            valueGetter: value => {
                if (!value) {
                    return null;
                }

                const {createdAt} = value || {};
                return createdAt;
            },
            valueFormatter: value => value ? moment(value).format('MM/DD/YYYY') : '',
            renderCell: params => {
                const {row} = params;
                const {lastRecord, path} = row || {};

                if (!path && !lastRecord) {
                    return null;
                }

                return (
                    <LastRecordCell lastRecord={lastRecord} path={path} />
                );
            }
        }
    ];
    
    let actions = [];

    if (Object.keys(checkTypes || {}).length) {
        for (const [key] of Object.entries(checkTypes)) {

            actions.push({
                icon: <ChecklistIcon />,
                label: `Start ${capitalize(key)} Check`,
                onClick: row => {
                    const {uid} = row || {};
                    handleStartCheck(key, uid);
                }
            });
        }
    }

    if (isCylinder) {
        columns.push({
            type: 'date',
            field: 'lastRefill',
            headerName: 'Last Refill',
            minWidth: 105,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '';
            }
        });

        actions.push({
            icon: <AutorenewIcon />,
            label: 'Refill',
            onClick: handleRefill
        });
    }

    if (actions.length) {
        columns.push({
            type: 'actions',
            field: 'actions',
            width: 50,
            getActions: params => {
                const {row} = params;
                const {uid} = row || {};

                return actions.map((action, index) => {
                    const {onClick, ...rest} = action;

                    return (
                        <GridActionsCellItem
                            showInMenu
                            key={`action-${index}-${uid}}`}
                            onClick={() => onClick(row)}
                            {...rest}
                        />
                    );
                });
            }
        });
    }

    const stateId = refArgs.slice(1).join('-');
    // const isEmpty = !rows || rows.length === 0;

    return (
        <Box {...props}>
            {isCylinder && (
                <RefillDialog onSubmit={onAddRefillRecord} open={!!refillOpen} handleClose={() => setRefillOpen(false)} />
            )}

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 1}}>
                <Typography variant="h6">Items</Typography>

                {hasPermission(currentUser, 'items.write') && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <ItemDialog parent={parent} onSubmit={onAdd} open={open} handleClose={() => setOpen(false)} />

                        {hasPermission(currentUser, 'items.delete') && (
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        onChange={() => setIncludeArchived(!includeArchived)}
                                        checked={includeArchived}
                                    />
                                )}
                                label="Include Archived"
                            />
                        )}
                        
                        <Button startIcon={<AddIcon />} disabled={loading} size="small" variant="outlined" onClick={handleAdd}>
                            Add New Item
                        </Button>
                    </Stack>
                )}
            </Stack>

            <Divider sx={{mb: 1}} />

            <SearchableDataGrid
                apiRef={apiRef}
                stateId={stateId}
                initialState={{
                    sorting: {
                        sortModel: [
                            {field: 'createdAt', sort: 'asc'}
                        ]
                    }
                }}
                rows={rows}
                autosizeOptions={autosizeOptions}
                localeText={{noRowsLabel: 'No items'}}
                loading={loading}
                columns={columns.map(column => ({...column, disableColumnMenu: true}))}
                pageSizeOptions={[]}
                disableRowSelectionOnClick
                onRowClick={onRowClick}
                getRowClassName={params => {
                    const {row} = params;
                    const {archived = false} = row || {};
                    const classNames = [];

                    if (archived) {
                        classNames.push('archived');
                    }

                    return classNames.join(' ');
                }}
                slotProps={{
                    loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'skeleton'
                    },
                    columnsManagement: {
                        getTogglableColumns: columns => {
                            return columns.filter(column => column.field !== 'actions').map(column => column.field);
                        }
                    }
                }}
                sx={{
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    }
                }}
            />
        </Box>
    );
};