import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Stack, Paper, Card, CircularProgress, Divider, Chip, useMediaQuery, Accordion, Button, TextField, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import {useParams, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import moment from 'moment';
import {collection, where, orderBy, limit, query, doc, onSnapshot, updateDoc, deleteDoc} from 'firebase/firestore';
import useDebouncedEffect from 'use-debounced-effect';
import {get, startCase, isEqual} from 'lodash';
import {httpsCallable} from 'firebase/functions';
import {useForm, useFieldArray, useFormContext, FormProvider} from 'react-hook-form';
import {useConfirm} from 'material-ui-confirm';
import WarningIcon from '@mui/icons-material/Warning';

import {UserContext} from '-/contexts/User';
import {db, functions} from '-/firebase';
import {hasFeature} from '-/features';

import {hasPermission, populateUsers, processRawDoc, getCollection, ensureJSDates} from '-/data/utils';

import UserAutocompleteField from '-/form/UserAutocompleteField';
import {Question, getRandomQuestion} from '-/pages/quizzes/FR';

const isDev = process.env.NODE_ENV === 'development';

const CompleteButton = props => {
    const {completed, active, ...rest} = props;

    return (
        <Button
            variant={completed ? 'contained' : 'outlined'}
            color="success"
            endIcon={<CheckIcon />}
            size="large"
            disabled={!active}
            {...rest}
        >
            {completed ? 'Completed' : 'Mark Complete'}
        </Button>
    );
};

const SkipButton = props => {
    const {skipped, active, ...rest} = props;

    return (
        <Button
            variant="outlined"
            color="secondary"
            endIcon={<SkipNextIcon />}
            size="large"
            disabled={!active}
            {...rest}
        >
            {skipped ? 'Skipped' : 'Skip'}
        </Button>
    );
};

const calculateNextExpandedIndex = (loggedChecks, expandedIndex) => {
    if (!loggedChecks || !loggedChecks.length) {
        return null;
    }

    let isInitialNull = false;
    if (!expandedIndex || expandedIndex.length !== 2) {
        expandedIndex = [0, 0];
        isInitialNull = true;
    }

    let [currentLogIndex, currentCheckIndex] = expandedIndex;

    for (let i = currentLogIndex; i < loggedChecks.length; i++) {
        const checks = loggedChecks[i].checks;
        const startCheckIndex = (i === currentLogIndex) ? currentCheckIndex : 0;

        for (let j = startCheckIndex; j < checks.length; j++) {
            const check = checks[j];
            if (!check) {
                continue;
            }

            if (!isInitialNull && i === currentLogIndex && j === currentCheckIndex) {
                continue;
            }

            if (!check.completed && !check.skipped) {
                return [i, j];
            }
        }
    }

    for (let i = currentLogIndex; i >= 0; i--) {
        const checks = loggedChecks[i].checks;
        const endCheckIndex = (i === currentLogIndex) ? Math.max(currentCheckIndex - 1, 0) : checks.length - 1;

        for (let j = endCheckIndex; j >= 0; j--) {
            const check = checks[j];
            if (!check) {
                continue;
            }
            
            if (!isInitialNull && i === currentLogIndex && j === currentCheckIndex) {
                continue;
            }

            if (!check.completed && !check.skipped) {
                return [i, j];
            }
        }
    }

    return null;
};

const Checks = props => {
    const {index: sectionIndex, active, expandedIndex, setExpandedIndex, onNextExpandedIndex, lastCheck} = props;
    const {control, getValues} = useFormContext();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    
    const name = `loggedChecks[${sectionIndex}].checks`;
    const {fields, update} = useFieldArray({
        control,
        name
    });

    const handleExpandedToggle = useCallback(nextIndex => {
        const newExpandedIndex = [sectionIndex, nextIndex];
        if (isEqual(expandedIndex, newExpandedIndex)) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(newExpandedIndex);
        }
    }, [sectionIndex, expandedIndex, setExpandedIndex]);

    const handleChange = useCallback((index, type) => {
        const {completed = false, skipped = false} = fields[index];
        const newCompleted = type === 'COMPLETE' ? !completed : false;
        const newSkipped = type === 'SKIP' ? !skipped : false;
        
        const updatedCheck = {
            ...fields[index],
            completed: newCompleted,
            skipped: newSkipped
        };
                
        update(index, updatedCheck);

        if (newCompleted || newSkipped) {
            setTimeout(() => {
                const currentLoggedChecks = getValues('loggedChecks');
                onNextExpandedIndex(currentLoggedChecks);
            }, 0);
        }
    }, [fields, update, onNextExpandedIndex, getValues]);

    const handleNoteChange = useCallback((index, note) => {
        update(index, {
            ...fields[index],
            note
        });
    }, [fields, update]);

    return (
        <Box>
            {fields.map((check, index) => {
                const {text, completed = false, skipped = false, note} = check;
                const checkIndex = [sectionIndex, index];
                const expanded = isEqual(checkIndex, expandedIndex);
                
                let accordionSx = undefined;
                if (completed && note) {
                    accordionSx = {
                        borderLeft: `4px solid ${theme.palette.warning.main}`
                    };
                } else if (skipped) {
                    accordionSx = {
                        borderLeft: `4px solid ${theme.palette.info.main}`
                    };
                }

                const previousCheck = lastCheck && lastCheck.loggedChecks
                    ? lastCheck.loggedChecks
                        .flatMap(section => section.checks || [])
                        .find(check => check.text === text)
                    : null; ;
                const previousSkipped = previousCheck && previousCheck.skipped === true;
                const previousNote = previousCheck && previousCheck.note;

                let severity;
                if (skipped) {
                    severity = 'info';
                } else if (completed && note) {
                    severity = 'warning-light';
                } else if (previousSkipped && !completed) {
                    severity = 'info-light';
                }

                return (
                    <Accordion key={index} expanded={expanded} {...severity && {severity}}>
                        <AccordionSummary component="div" sx={accordionSx}>
                            <Box sx={{display: 'flex', flex: 1, alignItems: 'center'}}>
                                <Box sx={{flex: 1, alignItems: 'center'}} onClick={() => handleExpandedToggle(index)}>
                                    {previousSkipped && !completed && (
                                        <Chip icon={<WarningIcon />} label="Skipped last time" color="info" size="small" sx={{mt: 0.5, mb: 1, pl: 0.5}} />
                                    )}
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            flex: 1,
                                            ...((completed || skipped) && {
                                                opacity: 0.6,
                                                textDecoration: 'line-through'
                                            })
                                        }}
                                    >
                                        {text}
                                    </Typography>
                                    {completed && note && (
                                        <Typography variant="caption" component="div">{note}</Typography>
                                    )}
                                    {skipped && (
                                        <Typography variant="caption" component="div">{['Skipped', note].filter(Boolean).join(': ')}</Typography>
                                    )}
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{pb: 1}}>
                            <Stack direction={isSmall ? 'column' : 'row'} spacing={1}>
                                <Stack direction="column" spacing={0.5} sx={{flex: 1}}>
                                    <TextField
                                        placeholder="Additional Notes"
                                        value={note || ''}
                                        onChange={e => handleNoteChange(index, e.target.value)}
                                        disabled={!active}
                                    />
                                    {previousNote && (
                                        <Card sx={{px: 1, py: 0.5}}>
                                            <Typography component="span" variant="caption" sx={{mr: 0.5, fontWeight: 'bold', color: 'text.secondary'}}>Previous note:</Typography>
                                            <Typography component="span" variant="caption">{previousNote}</Typography>
                                        </Card>
                                    )}
                                </Stack>
                                <CompleteButton
                                    completed={completed}
                                    active={active}
                                    onClick={() => handleChange(index, 'COMPLETE')}
                                />
                                <SkipButton
                                    skipped={skipped}
                                    active={active}
                                    onClick={() => handleChange(index, 'SKIP')}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};

const Sections = props => {
    const {active, expandedIndex, setExpandedIndex, lastCheck, onNextExpandedIndex} = props;
    const {control} = useFormContext();
    const {fields = []} = useFieldArray({
        control,
        name: 'loggedChecks'
    });

    const hasContainer = fields.length > 1 || get(fields[0], 'title');

    return (
        <Stack spacing={2}>
            {fields.map((section, index) => {
                const {id, title, checks = []} = section;
                const completed = checks.every(check => check.completed || check.skipped);

                return (
                    <Stack
                        key={`section-${id}`}
                        component={hasContainer ? Paper : null}
                        variant="outlined"
                        sx={{
                            p: hasContainer ? 1 : 0,
                            ...(completed && {
                                opacity: 0.6
                            })
                        }}
                        spacing={1}
                    >
                        {title && (
                            <Typography
                                variant="h5"
                                sx={{
                                    ...completed && {
                                        textDecoration: 'line-through'
                                    }
                                }}
                            >
                                {title}
                            </Typography>
                        )}

                        <Checks {...{index, active, expandedIndex, setExpandedIndex, lastCheck, onNextExpandedIndex}} />
                    </Stack>
                );
            })}
        </Stack>
    );
};

const Check = () => {
    const {id: uid, type = 'weekly', checkId: checkUid, '*': path = ''} = useParams();
    const [saving, setSaving] = useState(false);
    const [savingMinimumEndTime, setSavingMinimumEndTime] = useState(0);
    const [deleting, setDeleting] = useState(false);
    const [completing, setCompleting] = useState(false);
    const [lastCheck, setLastCheck] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [percentComplete, setPercentComplete] = useState(0);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const finishCheck = httpsCallable(functions, 'finishCheck');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const [showQuestion, setShowQuestion] = useState(false);
    const confirm = useConfirm();
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';
    const randomQuestion = getRandomQuestion();

    let collectionKey = 'apparatus';
    let rootCollectionKey = 'apparatus';

    try {
        if (path) {
            const parts = path.split('/');
            collectionKey = parts.slice(0, -2).join('/');
            rootCollectionKey = parts[0];
        }
    } catch(e) {
        console.warn('Error parsing path', e);
    }

    let isSubscribed = true;

    const methods = useForm({
        defaultValues: {
            active: false,
            users: [],
            loggedChecks: []
        },
        mode: 'onChange'
    });
    const {handleSubmit, watch, formState, trigger, reset, getValues} = methods;
    const {dirtyFields, isDirty} = formState;

    const active = watch('active', false);
    const createdAt = watch('createdAt');
    const updatedAt = watch('updatedAt');

    const startSaving = () => {
        let endTime = Date.now() + 1000;
        setSavingMinimumEndTime(endTime);
        setSaving(true);
        return endTime;
    };

    const endSaving = endTime => {
        const now = Date.now();
        const timeRemaining = (endTime || savingMinimumEndTime) - now;
        if (timeRemaining > 0) {
            setTimeout(() => {
                setSaving(false);
            }, timeRemaining);
        } else {
            setSaving(false);
        }
    };

    const handleFetch = useCallback(async checkRecordRaw => {
        try {
            let newCheckRecord = processRawDoc(checkRecordRaw);
            newCheckRecord = await populateUsers(db, newCheckRecord);

            if (!newCheckRecord) {
                return;
            }

            if (isSubscribed) {
                const {active: newActive} = newCheckRecord;
                const {loggedChecks = [], ...rest} = newCheckRecord;

                let newLoggedChecks = loggedChecks;
                if (loggedChecks.length && !loggedChecks[0].checks) {
                    newLoggedChecks = [{
                        id: 'default',
                        checks: loggedChecks
                    }];
                }
                
                reset({
                    ...rest,
                    loggedChecks: newLoggedChecks
                });

                if (firstLoad) {
                    setExpandedIndex(calculateNextExpandedIndex(newLoggedChecks, null));
                    setFirstLoad(false);

                    if (newActive) {
                        const ref = collection(db, collectionKey, 'checks');
                        const q = query(ref, where('type', '==', type), where('active', '==', false), orderBy('createdAt', 'desc'), limit(1));
                        const [lastCheck] = await getCollection(db, q);
                        setLastCheck(lastCheck);

                        setShowQuestion(hasFeature('quizzes'));
                    }
                }
            }
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [firstLoad, collectionKey]);

    useEffect(() => {
        const ref = doc(db, collectionKey, 'checks', checkUid);
        const snapshot = onSnapshot(ref, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [uid, checkUid, handleFetch, collectionKey]);

    const handleNextExpandedIndex = useCallback(currentLoggedChecks => {
        const nextExpandedIndex = calculateNextExpandedIndex(currentLoggedChecks, expandedIndex);
        setExpandedIndex(nextExpandedIndex);
    }, [expandedIndex]);

    const handleSave = useCallback(async () => {
        if (completing) {
            return;
        }

        let endTime = startSaving();

        try {
            const allValues = getValues();
            const {users: usersRaw, loggedChecks} = allValues;

            reset(allValues);

            const users = usersRaw.filter(Boolean).map(user => {
                return typeof user === 'string' ? user : user.id;
            });

            const docRef = doc(db, collectionKey, 'checks', checkUid);
            await updateDoc(docRef, ensureJSDates({
                loggedChecks,
                users,
                updatedAt: new Date()
            }));
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        endSaving(endTime);
    }, [uid, checkUid, completing, collectionKey]);

    const handleFinish = useCallback(async data => {
        await trigger();

        const {users = [], loggedChecks = []} = data;

        if (!users.length) {
            const field = document.querySelector('[name="users"]');
            if (field) {
                if (field.scrollIntoViewIfNeeded) {
                    field.scrollIntoViewIfNeeded();
                }
                field.focus();
            }
            
            enqueueSnackbar('Please select members to finish this check', {variant: 'error'});
            return;
        }

        const onFinish = async () => {
            setCompleting(true);

            await handleSave();

            try {
                await finishCheck({
                    path: collectionKey,
                    check: checkUid,
                    debugEmail: isDev
                });

                navigate(-1);
            } catch (e) {
                enqueueSnackbar(e.message, {variant: 'error'});
                setCompleting(false);
            }
        };

        const canFinish = calculateNextExpandedIndex(loggedChecks, expandedIndex) === null;
        if (!canFinish) {
            const {confirmed} = await confirm({
                description: 'You have incomplete checks. Do you still want to finish the check?',
                confirmationText: 'Finish Check'
            });
          
            if (confirmed) {
                onFinish();
            }
        } else {
            onFinish();
        }
    }, [uid, checkUid, handleSave, finishCheck, expandedIndex]);

    const handleDelete = useCallback(() => {
        const onDelete = async () => {
            try {
                setDeleting(true);
    
                const docRef = doc(db, collectionKey, 'checks', checkUid);
                await deleteDoc(docRef);
            } catch (e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
    
            navigate(-1);
        };

        if (active) {
            onDelete();
            return;
        }

        enqueueSnackbar('Are you sure you want to delete this check?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [db, enqueueSnackbar, navigate, uid, checkUid, closeSnackbar, active, collectionKey]);

    useDebouncedEffect(async () => {
        if (isDirty && !completing) {
            await handleSave();
        }
    }, 2000, [completing, dirtyFields, handleSave]);

    useEffect(() => {
        const {total, complete} = getValues('loggedChecks').reduce((acc, section) => {
            const {checks} = section;
            const completedChecks = checks.filter(check => check && (check.completed || check.skipped));
            acc.total += checks.length;
            acc.complete += completedChecks.length;
            return acc;
        }, {total: 0, complete: 0});
        
        setPercentComplete(total ? Math.round((complete / total) * 100) : 0);
    }, [dirtyFields]);

    return (
        <FormProvider {...methods}>
            <Box sx={{pb: 2}}>
                <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={1}>
                    <Stack direction="column">
                        <Typography variant="h4">
                            {startCase(type)} Check
                        </Typography>
                        <Typography variant="caption">
                            <strong>Started:</strong> {moment(createdAt ? (createdAt.toDate ? createdAt.toDate() : createdAt) : new Date()).format(dateFormatLong)}
                        </Typography>
                        {updatedAt && (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="caption">
                                    <strong>Last saved:</strong> {updatedAt.fromNow()}
                                </Typography>
                                {saving && <CircularProgress size={12} />}
                            </Stack>
                        )}
                    </Stack>
                    {active && (
                        <Button
                            variant="contained"
                            onClick={handleSubmit(handleFinish)}
                            loading={completing}
                            disabled={deleting || completing}
                            size="small"
                            sx={{minHeight: 60, px: 2}}
                        >
                            <Stack direction="column" alignItems="center">
                                <>Finish Check</>
                                <Typography variant="caption" sx={{opacity: 0.8}}>{percentComplete}% complete</Typography>
                            </Stack>
                        </Button>
                    )}
                </Stack>

                <Divider sx={{mt: 1}} />
            </Box>

            {showQuestion && (
                <Question question={randomQuestion} title="Random First Response Question" sx={{mb: 2}} finishText="Close" onFinish={() => setShowQuestion(false)} />
            )}
        
            <UserAutocompleteField
                label="Members"
                name="users"
                limitToStation
                required
                disabled={!active}
                sx={{flex: 1}}
            />

            <Divider sx={{mt: 1, mb: 2}} />

            <Sections {...{saving, active, expandedIndex, setExpandedIndex, lastCheck, onNextExpandedIndex: handleNextExpandedIndex}} />
        
            {active && (
                <Button
                    variant="contained"
                    sx={{mt: 2}}
                    onClick={handleSubmit(handleFinish)}
                    loading={completing}
                    disabled={deleting || completing}
                    size="large"
                    fullWidth
                >
                    <Stack direction="column" alignItems="center">
                        <>Finish Check</>
                        <Typography variant="caption" sx={{opacity: 0.8}}>{percentComplete}% complete</Typography>
                    </Stack>
                </Button>
            )}

            {(hasPermission(currentUser, `${rootCollectionKey}.write`) || active) && (
                <Button
                    variant="outlined"
                    sx={{mt: 1}}
                    onClick={handleDelete}
                    loading={deleting}
                    disabled={deleting || completing}
                    fullWidth
                >
                    {active ? 'Cancel' : 'Delete'} Check
                </Button>
            )}
        </FormProvider>
    );
};

export default Check;