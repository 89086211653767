import React, {useState, useMemo, useEffect, useCallback, useContext} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Divider, Stack, IconButton, Box, Typography, Skeleton, Menu, MenuItem, ListItemText} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useFormContext} from 'react-hook-form';
import {get, snakeCase, startCase, isString, omit} from 'lodash';
import {doc, updateDoc, deleteDoc, onSnapshot, deleteField} from 'firebase/firestore';
import EditIcon from '@mui/icons-material/Edit';
import * as MaterialIcons from '@mui/icons-material';
import {ref, getDownloadURL} from 'firebase/storage';
import {useConfirm} from 'material-ui-confirm';

import * as Icons from '-/components/Icons';

import {db, storage} from '-/firebase';
import {UserContext} from '-/contexts/User';
import {hasFeature} from '-/features';

import {processRawDoc, getCollectionDoc, hasPermission, ensureJSDates, uploadImage} from '-/data/utils';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import ArchivedAlert from '-/components/ArchivedAlert';

import CategoryDialog from './gear/CategoryDialog';
import CategoriesGrid from './gear/CategoriesGrid';
import ItemsGrid, {useItems} from './gear/ItemsGrid';
import TasksGrid from '-/pages/tasks/TasksGrid';

import TextField from '-/form/TextField';
import SelectField from '-/form/SelectField';

import Placement from './gear/collection/Placement';

import Prompt from '-/dialogs/Prompt';

const AddCheckPrompt = () => {
    const {watch} = useFormContext();
    const {type} = watch();

    return (
        <Stack spacing={2}>
            <Typography variant="body1">Please select the type of check you would like to add:</Typography>

            <SelectField
                name="type"
                label="Type"
                options={[
                    {label: 'Inspection', value: 'inspection'},
                    {label: 'Weekly', value: 'weekly'},
                    {label: 'Other', value: 'other'}
                ]}
                required
            />

            {type === 'other' && (
                <TextField
                    name="otherType"
                    label="Type"
                    required
                />
            )}
        </Stack>
    );
};

const EditButton = props => {
    const {checks, onEdit} = props;
    const {id: uid} = useParams();
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const [prompOpen, setPromptOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const canEdit = hasPermission(currentUser, 'gear.write');
    const canEditChecks = hasPermission(currentUser, 'editChecks.write');

    if (!canEdit || !canEditChecks) {
        return null;
    }

    const handleAddCheck = async data => {
        const {type, otherType} = data;
        let newType = type;
        if (type === 'other') {
            newType = otherType;
        }

        newType = snakeCase(newType.toLowerCase());

        handlePromptClose();

        navigate(`${newType}/edit`);
    };

    const handlePromptClose = () => {
        setPromptOpen(false);
    };

    return (
        <Stack direction="row" spacing={1}>
            <IconButton  onClick={handleOpen} data-testid="edit-menu-button">
                <EditIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {canEdit && (
                    <MenuItem onClick={onEdit}>
                        <ListItemText>Edit Category</ListItemText>
                    </MenuItem>
                )}

                {canEdit && canEditChecks && checks && Object.keys(checks).length > 0 && <Divider />}

                {canEditChecks && (
                    <>
                        {checks && Object.entries(checks).map(([check, id]) => {
                            return (
                                <MenuItem key={id} onClick={() => navigate(`${check}/edit`)}>
                                    <ListItemText>Edit {startCase(check)} Check</ListItemText>
                                </MenuItem>
                            );
                        })}

                        <Divider />

                        <MenuItem onClick={() => setPromptOpen(true)}>
                            <ListItemText>Add New Check</ListItemText>
                        </MenuItem>
                    </>
                )}
            </Menu>

            <Prompt
                open={prompOpen}
                handleClose={handlePromptClose}
                title="Add New Check"
                submitText="Add Check"
                onSubmit={handleAddCheck}
                prompt={<AddCheckPrompt />}
            />
        </Stack>
    );
};

export default function GearCollection() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {items} = useItems();
    const confirm = useConfirm();

    const {name, icon, apparatus, image, additionalFields = [], archived, archivedAt, archivedBy, checks: checkTypes} = row || {};
    const Icon = !loading && icon && icon.value && ((Icons || {})[icon.value] || MaterialIcons[icon.value]);

    const refArgs = useMemo(() => {
        let refArgs = [db];

        Object.entries(params).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        return refArgs;
    }, [db, params]);

    const deleteUrl = useMemo(() => {
        let parts = [];

        Object.entries(params).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        if (parts.length === 2) {
            return '/gear';
        }

        return `/${parts.slice(0, -2).join('/')}`;
    }, [params]);
    
    useEffect(() => {
        setLoading(true);

        const ref = doc(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            if (!snapshot.exists()) {
                navigate(deleteUrl);
                return;
            }

            const row = processRawDoc(snapshot);
            if (!row) {
                return;
            }

            const {icon, apparatus: rawApparatus, ...rest} = row;
            const apparatusUid = isString(rawApparatus) ? rawApparatus : get(rawApparatus, 'uid');
            let apparatus = await getCollectionDoc(db, 'apparatus', apparatusUid);

            setRow({
                ...rest,
                icon: icon ? {value: icon, label: icon} : null,
                apparatus
            });

            setLoading(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs, deleteUrl, navigate]);

    useDocumentTitle(name);

    const onUpdate = async data => {
        setLoading(true);

        const {icon, imageFile, apparatus, ...rest} = omit(data, 'uid');

        const toUpdate = ensureJSDates(omit(rest, 'image'));

        if (imageFile) {
            const path = refArgs.slice(1).join('/');
            await uploadImage(path, imageFile);
        } else if (imageFile === null) {
            toUpdate.image = deleteField();
        }

        toUpdate.icon = icon ? (icon.value || icon) : null;

        if (apparatus) {
            toUpdate.apparatus = typeof apparatus === 'string' ? apparatus : apparatus.uid;
        } else {
            toUpdate.apparatus = null;
        }

        if (Object.keys(toUpdate)) {
            await updateDoc(doc(...refArgs), {
                ...toUpdate,
                updatedAt: new Date()
            });

            if (toUpdate.apparatus) {
                toUpdate.apparatus = await getCollectionDoc(db, 'apparatus', toUpdate.apparatus);
            }

            setRow({
                ...toUpdate,
                image,
                ...icon && {icon: {value: icon, label: icon}}
            });
        }

        setLoading(false);

        return true;
    };

    const handleDelete = useCallback(async () => {
        const onDelete = async() => {
            setLoading(true);

            try {
                const ref = doc(...refArgs);
                await deleteDoc(ref);

                navigate(deleteUrl);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
                setLoading(false);
            }
        };

        const {confirmed} = await confirm({
            description: `Are you sure you want to delete ${name}? All information, items and categories will be deleted.`,
            confirmationText: `Delete ${name || 'Category'}`
        });

        if (confirmed) {
            onDelete();
        }
    }, [refArgs, enqueueSnackbar, closeSnackbar, navigate, deleteUrl, name]);

    const handleArchive = useCallback(async item => {
        setLoading(true);

        try {
            const {archived} = item;
            const ref = doc(...refArgs);
            await updateDoc(ref, ensureJSDates({
                archived: !archived,
                ...(!archived && {
                    archivedAt: new Date(),
                    archivedBy: get(currentUser, 'uid')
                }),
                updatedAt: new Date()
            }));
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            setLoading(false);
        }
    }, [refArgs, closeSnackbar, navigate, deleteUrl, name]);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };
        
        setImageUrl(null);
        fetch();

        return () => isSubscribed = false;
    }, [image]);

    const hasPlacement = imageUrl && items.length > 0 && items.some(item => item.position);

    return (
        <Box>
            {hasPermission(currentUser, 'gear.write') && (
                <CategoryDialog onSubmit={onUpdate} onDelete={handleDelete} onArchive={handleArchive} item={row} open={open} handleClose={() => setOpen(false)} />
            )}

            <Stack direction="row" spacing={1} sx={{mb: 1, alignItems: 'center'}}>
                {imageUrl && (
                    <Box sx={{aspectRatio: '4/3', height: 50, borderRadius: 1, backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}} />
                )}
                {Icon && !imageUrl && (
                    <Icon sx={{fontSize: 50}} />
                )}
                {loading && (
                    <Skeleton variant="rectangular" sx={{height: 50, width: 50, borderRadius: 1}} />
                )}
                <Box sx={{flex: 1}}>
                    <Stack direction="column" sx={{flex: 1}}>
                        <Typography variant="h5">{loading ? <Skeleton /> : name}</Typography>
                        <Typography variant="caption" color="text.secondary" sx={{lineHeight: 1}}>E51</Typography>
                        {apparatus && (
                            <Typography variant="caption" color="text.secondary" sx={{lineHeight: 1}}>{loading ? <Skeleton /> : get(apparatus, 'tag')}</Typography>
                        )}
                    </Stack>
                </Box>
                {hasPermission(currentUser, 'gear.write') && (
                    <EditButton checks={checkTypes} onEdit={() => setOpen(true)} />
                )}
            </Stack>

            <Divider sx={{mb: 2}} />

            <ArchivedAlert label="category" {...{archived, archivedAt, archivedBy}} />

            <CategoriesGrid archived={archived} />

            {hasFeature('tasks') && <TasksGrid sx={{mt: 2}} />}

            {!loading && additionalFields.length > 0 && (
                <>
                    {hasPlacement && <Placement imageUrl={imageUrl} />}
                    
                    <ItemsGrid />
                </>
            )}
        </Box>
    );
};