import React, {useEffect, useState, useContext, useMemo} from 'react';
import {Box, Typography, Breadcrumbs, Link, Skeleton, useMediaQuery} from '@mui/material';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {collection, doc, getDoc} from 'firebase/firestore';
import moment from 'moment';
import {get, castArray, startCase} from 'lodash';
import {useTheme} from '@mui/material/styles';

import {db} from '-/firebase';

import {populateCollectionIds, populateKeyFromCollection, processRawDoc} from '-/data/utils';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import {UserContext} from '-/contexts/User';

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const generateDynamicBreadcrumbs = (collection, field, defaultValue) => {
    const collections = castArray(collection);

    return ({match}) => {
        const {currentUser} = useContext(UserContext);
        
        const {params} = match;
        const keys = Object.keys(params);
        const [record, setRecord] = useState(null);
        const [parentRecord, setParentRecord] = useState(null);

        const args = useMemo(() => [
            db,
            ...keys.reduce((acc, key, index) => {
                acc.push(collections[index]);
                acc.push(params[key]);

                return acc;
            }, [])
        ], [db, keys]);

        const parentArgs = useMemo(() => {
            const result = args.slice(0, args.length - 2);
            if (result.length > 1) {
                return result;
            }

            return [];
        }, [args]);
    
        useEffect(() => {
            let isSubscribed = true;
    
            const fetch = async() => {
                try {
                    const ref = doc(...args);
                    const raw = await getDoc(ref);

                    let parentDoc;
                    if (parentArgs.length > 0) {
                        const parentRef = doc(...parentArgs);
                        const parentRaw = await getDoc(parentRef);

                        if (parentRaw.exists()) {
                            parentDoc = processRawDoc(parentRaw);
                        }
                    }
                    
                    if (isSubscribed) {
                        let doc = processRawDoc(raw);
                        const apparatus = get(doc, 'apparatus');
                        if (apparatus) {
                            doc = await populateKeyFromCollection(db, doc, 'apparatus', 'apparatus');
                        }

                        setRecord(doc);
                        setParentRecord(parentDoc);
                    }
                } catch(e) {
                    console.warn('generateDynamicBreadcrumbs', e);
                }
            };
    
            fetch();
    
            return () => isSubscribed = false;
        }, []);
    
        if (!record) {
            return <Skeleton variant="text" width={60} />;
        }

        if (field === true) {
            try {
                const {additionalFields = []} = parentRecord || {};
                const [firstField] = additionalFields;
                if (firstField) {
                    const {id} = firstField;
                    const rawValue = get(record, id);

                    return convertFieldValue(null, rawValue, record, currentUser) || defaultValue || get(record, 'id');
                }
            } catch(e) {
                console.warn('generateDynamicBreadcrumbs', e);
            }

            return defaultValue || get(record, 'id');
        }


        if (typeof field === 'function') {
            return field(record);
        }

        let rawValue = get(record, field);


        if (Array.isArray(field)) {
            const values = field.map(f => get(record, f)).filter(Boolean);
            if (values.length) {
                rawValue = values[0];
            }
        }

        if (rawValue) {
            return convertFieldValue(field, rawValue, record, currentUser) || defaultValue || get(record, 'id');
        }

        return get(record, field) || defaultValue || get(record, 'id');
    };
};

const convertFieldValue = (field, value, record, currentUser) => {
    if (!value) {
        return;
    }

    if (value.toDate) {
        const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
        return moment(value.toDate()).format(dateFormat);
    } else if (field === 'apparatus') {
        const apparatus = get(record, 'apparatus');
        if (apparatus) {
            return get(apparatus, 'tag');
        }
    }

    return value;
};

const DynamicChecksBreadcrumbs = ({match}) => {
    const {params, pathname} = match;
    const {apparatusId, id} = params;
    const [doc, setDoc] = useState(null);
    const {currentUser} = useContext(UserContext);
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            try {
                const refPath = pathname.split('/').slice(0, -2).filter(Boolean).join('/');
                const ref = collection(db, refPath, 'checks');
                const docs = await populateCollectionIds(db, ref, [id]);
                
                if (isSubscribed) {
                    setDoc(docs[id]);
                }
            } catch(e) {
                console.warn('DynamicChecksBreadcrumbs', e);
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, id, apparatusId]);

    let {createdAt} = doc || {};
    if (createdAt) {
        createdAt = moment(createdAt.toDate()).format(dateFormatLong);
    }

    if (!doc) {
        return <Skeleton variant="text" width={60} />;
    }

    return createdAt || id;
};

const generateStartCase = (field, process) => {
    return ({match}) => {
        const {params} = match;
        const value = get(params, field);
        const string = startCase(value);
        return process ? process(string) : string;
    };
};

const generateGearBreadcrumbPaths = (maxDepth = 10) => {
    const paths = [
        {path: 'gear/:id', breadcrumb: generateDynamicBreadcrumbs(['gear'], 'name', 'Category')},
        {path: 'gear/:id/:type', breadcrumb: false} //generateStartCase('type', string => `${string} Check`)}
    ];

    let path = 'gear/:collectionId';
    let collectionNames = ['gear'];

    for (let depth = 1; depth <= maxDepth; depth++) {
        if (depth === 1) {
            // First level: gear/:collectionId/:id with breadcrumb as ['gear', 'items']
            paths.push({
                path: `${path}/items`,
                breadcrumb: false
            });

            paths.push({
                path: `${path}/items/:id`,
                breadcrumb: generateDynamicBreadcrumbs([...collectionNames, 'items'], true, 'Item')
            });

            paths.push({
                path: `${path}/items/:id/:type/:id`,
                breadcrumb: DynamicChecksBreadcrumbs
            });
        } else if (depth % 2 === 0) {
            // Even depth: add `/gear` for collection, then `/gear/:id` with breadcrumb ['gear', ..., 'items']
            path += '/gear';

            paths.push({
                path: `${path}/:id`,
                breadcrumb: generateDynamicBreadcrumbs([...collectionNames, 'gear', 'items'], 'name', 'Category')
            });

            // paths.push({
            //     path: `${path}/:id/:type`,
            //     breadcrumb: generateStartCase('type', string => `${string} Check`)
            // });

            paths.push({
                path: `${path}/:id/items`,
                breadcrumb: null
            });
        } else {
            // Odd depth: add `/:subCollectionIdX/:id` for item breadcrumb with ['gear', ..., 'items']
            path += `/:subCollectionId${Math.floor((depth - 2) / 2)}`;
            paths.push({
                path: `${path}/items/:id`,
                breadcrumb: generateDynamicBreadcrumbs([...collectionNames, 'items'], true, 'Item')
            });

            paths.push({
                path: `${path}/items/:id/:type/:id`,
                breadcrumb: DynamicChecksBreadcrumbs
            });
        }

        // Update collectionNames to add 'gear' for each collection depth, ending with 'items' as needed
        if (depth % 2 !== 0) {
            collectionNames.push('gear');
        }
    }

    return paths;
};

const routes = [
    {path: '/users/:id', breadcrumb: generateDynamicBreadcrumbs('users', ['fullName', 'email'], 'User')},
    {path: '/users/:id/skills', breadcrumb: 'Skills'},
    {path: '/users/:id/statistics', breadcrumb: 'Statistics'},
    {path: '/users/:id/transcript', breadcrumb: 'Transcript'},
    {path: '/users/:id/jibcConsent', breadcrumb: 'JIBC Consent Form'},
    {path: '/users/:id/drivertraining', breadcrumb: 'Driver Training'},
    {path: '/apparatus/:id', breadcrumb: generateDynamicBreadcrumbs('apparatus', 'tag', 'Apparatus')},
    {path: '/apparatus/add', breadcrumb: 'Add'},
    {path: '/apparatus/:apparatusId/edit', breadcrumb: 'Edit'},
    {path: '/apparatus/:apparatusId/:type', breadcrumb: generateStartCase('type', string => `${string} Check`)},
    {path: '/apparatus/:apparatusId/:type/:id', breadcrumb: DynamicChecksBreadcrumbs},
    {path: '/apparatus/:apparatusId/:type/edit', breadcrumb: 'Edit'},
    {path: '/apparatus/:apparatusId/:type/start', breadcrumb: 'Start'},
    {path: '/matrix', breadcrumb: false},
    {path: '/matrix/qualification', breadcrumb: 'Qualification Matrix'},
    {path: '/matrix/training', breadcrumb: 'Training Matrix'},
    {path: '/calendar', breadcrumb: 'Calendar'},
    {path: '/profile', breadcrumb: 'Profile'},
    {path: '/profile/skills', breadcrumb: 'Skills'},
    {path: '/profile/statistics', breadcrumb: 'Statistics'},
    {path: '/profile/transcript', breadcrumb: 'Transcript'},
    {path: '/users', breadcrumb: 'Users'},
    {path: '/users/add', breadcrumb: 'Add'},
    {path: '/ropes', breadcrumb: 'Ropes'},
    {path: '/quizzes/fr', breadcrumb: 'First Responder'},
    {path: '/drivertraining', breadcrumb: 'Driver Training'},
    {path: '/drivertraining/logs', breadcrumb: 'Logs'},
    {path: '/incidents', breadcrumb: 'Incidents'},
    {path: '/incidents/add', breadcrumb: 'Add'},
    {path: '/incidents/:id', breadcrumb: generateDynamicBreadcrumbs('incidents', ['incidentNumber', 'date'], 'Incident')},
    {path: '/incidents/import', breadcrumb: 'Import'},
    {path: '/training', breadcrumb: 'Training Records'},
    {path: '/training/add', breadcrumb: 'Add'},
    {path: '/training/:id', breadcrumb: generateDynamicBreadcrumbs('training', ['title', 'raw.type', 'date'], 'Record')},
    {path: '/training/keywords', breadcrumb: 'Keywords'},
    {path: '/training/import', breadcrumb: 'Import'},
    {path: '/vs', breadcrumb: 'Vector Solutions'},
    {path: '/vs/activities', breadcrumb: 'Activities'},
    {path: '/nfpa', breadcrumb: 'NFPA'},
    {path: '/gear', breadcrumb: 'Gear'},
    {path: '/jibcSkills', breadcrumb: 'JIBC Skills'},
    {path: '/respond', breadcrumb: 'Respond'},
    {path: '/respond/:id', breadcrumb: generateDynamicBreadcrumbs('responses', 'location')},
    ...generateGearBreadcrumbPaths()
];

const HeaderBreadcrumbs = () => {
    let breadcrumbs = useBreadcrumbs(routes);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {pathname} = useLocation();

    if (pathname === '/') {
        breadcrumbs = [];
    }

    let text;
    if (breadcrumbs.length > 0) {
        const {breadcrumb} = breadcrumbs[breadcrumbs.length - 1];
        text = get(breadcrumb, 'props.children');
    }

    useDocumentTitle(text);

    if (!breadcrumbs.length) {
        return (
            <Box sx={{pt: 2}} />
        );
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{mb: isSmall ? 2 : 3}}>
            {breadcrumbs.reduce((result, item, index) => {
                const {breadcrumb, match, key} = item;
                const last = breadcrumbs.length - 1 === index;
                const ignoreLink = last || key === '/matrix' || get(match, 'route.breadcrumb.ignoreLink') === false;

                if (get(match, 'route.breadcrumb') === false) {
                    return result;
                }

                result.push(ignoreLink ? (
                    <Typography color="textPrimary" key={key}>
                        {breadcrumb}
                    </Typography>
                ) : (
                    <LinkRouter color="inherit" to={key} key={key}>
                        {breadcrumb}
                    </LinkRouter>
                ));

                return result;
            }, [])}
        </Breadcrumbs>
    );
};

export default HeaderBreadcrumbs;
