import React, {useState, useEffect, useMemo, useContext, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {Grid2 as Grid, Button, Stack, IconButton, Box, Typography, Paper, Skeleton} from '@mui/material';
import {collection, query, orderBy, addDoc, where, doc, updateDoc, deleteDoc, limit, onSnapshot} from 'firebase/firestore';
import {get, omit, castArray, startCase, last} from 'lodash';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import {ref, getDownloadURL} from 'firebase/storage';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {useConfirm} from 'material-ui-confirm';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FireTruckIcon from '@mui/icons-material/FireTruck';

import {db, storage} from '-/firebase';
import {UserContext} from '-/contexts/User';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import {getCollectionDoc, getCollection, processRawDocs, processRawDoc, ensureJSDates, ensureMomentDates, hasPermission, uploadImage} from '-/data/utils';
import {hasFeature} from '-/features';

import ArchivedAlert from '-/components/ArchivedAlert';

import RecordsGrid from './gear/RecordsGrid';
import ItemDialog from './gear/ItemDialog';
import TasksGrid from '-/pages/tasks/TasksGrid';

const HeaderItem = ({label, value, loading, ...rest}) => (
    <Grid {...rest} size={{xs: 12, md: 6, lr: 4}}>
        <Typography variant="caption" color="text.secondary">{loading ? <Skeleton /> : label}</Typography>
        <Typography variant="h6">{loading ? <Skeleton /> : value}</Typography>
    </Grid>
);

export default function GearItem() {
    const params = useParams();
    const {id: uid, ...otherParams} = params;
    const [loadingItem, setLoadingItem] = useState(true);
    const [loadingParent, setLoadingParent] = useState(true);
    const [loadingChecks, setLoadingChecks] = useState(true);
    const [editing, setEditing] = useState(false);
    const [parent, setParent] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [item, setItem] = useState(null);
    const [activeChecks, setActiveChecks] = useState([]);
    const confirm = useConfirm();
    const {currentUser} = useContext(UserContext);
    const {isStation, uid: currentUserUid} = currentUser;

    const loading = loadingItem || loadingParent || loadingChecks;

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    const {name, image = [], archived, archivedAt, archivedBy} = item || {};
    const {additionalFields = [], checks: checkTypes = {}} = parent || {};

    const deleteUrl = useMemo(() => {
        let parts = [];

        Object.entries(otherParams).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        return `/${parts.join('/')}`;
    }, [otherParams]);

    const parentRefArgs = useMemo(() => {
        const otherParams = omit(params, 'id');
        let refArgs = [db];

        Object.entries(otherParams).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        return refArgs;
    }, [db, params]);

    const parentUid = useMemo(() => {
        return last(parentRefArgs);
    }, [parentRefArgs]);

    const refArgs = useMemo(() => {
        let refArgs = [...parentRefArgs];

        refArgs.push('items', uid);

        return refArgs;
    }, [uid, parentRefArgs]);

    const checksRefArgs = useMemo(() => {
        return [...refArgs, 'checks'];
    }, [refArgs]);

    useEffect(() => {
        setLoadingParent(true);

        const ref = doc(...parentRefArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            const row = processRawDoc(snapshot);

            setParent(row);
            setLoadingParent(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, uid, parentRefArgs]);

    useEffect(() => {
        setLoadingChecks(true);

        const ref = collection(...checksRefArgs);
        const q = query(ref, where('active', '==', true), orderBy('createdAt', 'desc'));
        const unsubscribe = onSnapshot(q, async snapshot => {
            const checks = processRawDocs(snapshot);

            setActiveChecks(checks);

            setLoadingChecks(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, checksRefArgs]);

    useEffect(() => {
        setLoadingItem(true);

        const ref = doc(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            const record = processRawDoc(snapshot);
            if (!record) {
                return;
            }

            const {image, ...rest} = record;
            const result = ensureMomentDates({
                ...rest,
                ...image && {image: castArray(image)}
            });

            for (const field of additionalFields) {
                const {id, type} = field;
                const value = record[id];

                if (type === 'user') {
                    const user = await getCollectionDoc(db, 'users', value);
                    result[id] = user;
                } else if (type === 'apparatus') {
                    const apparatus = await getCollectionDoc(db, 'apparatus', value);
                    result[id] = apparatus;
                }
            }

            setItem(result);

            setLoadingItem(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, uid, refArgs, parent]);

    const onUpdate = useCallback(async data => {
        setLoadingItem(true);

        const {imageFile, image = [], imageDeleted = [], ...rest} = data;

        try {
            if (imageDeleted.length) {
                await updateDoc(doc(...refArgs), ensureJSDates({
                    image: image.filter(({id}) => !imageDeleted.some(d => d.id === id))
                }));
            }

            const path = refArgs.slice(1).join('/');
            if (imageFile) {
                await Promise.all(imageFile.map(async file => {
                    return await uploadImage(path, file, true);
                }));
            }

            const toUpdate = ensureJSDates({
                ...rest
            });

            if (Object.keys(toUpdate)) {
                const update = {
                    ...toUpdate,
                    updatedAt: new Date()
                };

                for (const field of additionalFields) {
                    const {id, type} = field;
                    const value = update[id];

                    if (type !== 'date' && typeof value === 'object') {
                        update[id] = value?.uid || null;
                    }
                }

                await updateDoc(doc(...refArgs), update);
            }

            setEditing(false);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoadingItem(false);
    }, [parent]);

    const handleDelete = async() => {
        const onDelete = async() => {
            setLoadingItem(true);

            try {
                const ref = doc(...refArgs);
                await deleteDoc(ref);

                navigate(deleteUrl);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
        };

        const {confirmed} = await confirm({
            description: 'Are you sure you want to delete this item?',
            confirmationText: 'Delete Item'
        });

        if (confirmed) {
            onDelete();
        }
    };

    const handleArchive = async item => {
        setLoadingItem(true);

        try {
            const {archived} = item;
            const ref = doc(...refArgs);
            await updateDoc(ref, ensureJSDates({
                archived: !archived,
                ...(!archived && {
                    archivedAt: new Date(),
                    archivedBy: get(currentUser, 'uid')
                }),
                updatedAt: new Date()
            }));
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoadingItem(false);
    };

    useDocumentTitle(name || 'Item');

    useEffect(() => {
        if (!image || !image.length) {
            return;
        }

        const [firstImage] = image;
        const {filePath, thumbnailPath} = firstImage || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };

        setImageUrl(null);
        fetch();

        return () => isSubscribed = false;
    }, [image]);

    const handleStartCheck = async type => {
        let checkId;

        const activeCheck = activeChecks.find(check => check.type === type);
        if (activeCheck) {
            checkId = activeCheck.uid;
        } else {
            try {

                const ref = collection(db, 'checks');
                const q = query(ref, where('gear', '==', parentUid), where('type', '==', type), orderBy('createdAt', 'desc'), limit(1));
                const docs = await getCollection(db, q);

                const [record] = docs || [];
                if (!record) {
                    enqueueSnackbar('Check not found. Please contact the administrator.', {variant: 'error'});
                    return;
                }

                const {sections = [], checks = []} = record;

                let data = {
                    type,
                    createdAt: new Date(),
                    active: true,
                    users: !isStation && currentUserUid ? [currentUserUid] : [],
                    loggedChecks: sections.length ? sections : [{checks}]
                };
            
                const checkRef = collection(...refArgs, 'checks');
                const checkRecordRaw = await addDoc(checkRef, data);

                checkId = checkRecordRaw.id;
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
        }

        if (checkId) {
            navigate(`${type}/${checkId}`);
        }
    };

    return (
        <Box>
            {hasPermission(currentUser, 'gear.write') && editing && (
                <ItemDialog parent={parent} item={item} onSubmit={onUpdate} onDelete={handleDelete} onArchive={handleArchive} open={editing} handleClose={() => setEditing(false)} />
            )}

            <ArchivedAlert label="item" {...{archived, archivedAt, archivedBy}} />
            
            <Paper variant="outlined" sx={{p: 2, position: 'relative', mb: 1, minHeight: 55}}>
                <Grid spacing={2} container>
                    {imageUrl && (
                        <Grid
                            size={{xs: 12, md: 4}}
                            sx={{aspectRatio: '4/3', borderRadius: 1, backgroundImage: `url(${imageUrl})`, backgroundPosition: 'center', backgroundSize: 'cover'}}
                            onClick={() => window.open(imageUrl, '_blank')}
                        />
                    )}

                    <Grid size={{xs: 12, md: imageUrl ? 8 : 12}} spacing={1} container>
                        {additionalFields.map((field, index) => {
                            const {id, label, type} = field;
                            const rawValue = (item || {})[id];
                            let value;

                            if (rawValue) {
                                if (type === 'date') {
                                    value = moment(rawValue).format(dateFormat);
                                } else if (type === 'apparatus') {
                                    const {tag} = rawValue || {};
                                    value = tag || '-';
                                } else if (type === 'user') {
                                    const {fullName, email} = rawValue || {};
                                    value = fullName || email || '-';
                                } else if (type === 'boolean') {
                                    value = rawValue ? <CheckIcon /> : <DoNotDisturbIcon />;
                                } else {
                                    value = rawValue;
                                }
                            } else if (type === 'boolean') {
                                value = <DoNotDisturbIcon />;
                            }

                            return (
                                <HeaderItem key={`additional-fields-${index}`} label={label} loading={loading} value={value || '-'} />
                            );
                        })}
                    </Grid>
                </Grid>
            
                {hasPermission(currentUser, 'items.write') && (
                    <IconButton disabled={loading} sx={{ml: 1, position: 'absolute', bottom: 10, right: 10}} onClick={() => setEditing(true)}>
                        <EditIcon />
                    </IconButton>
                )}
            </Paper>

            {Object.keys(checkTypes).length > 0 && (
                <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{mb: 2, flexWrap: 'wrap'}} flexWrap="wrap" useFlexGap>
                    {Object.keys(checkTypes).map((type, index) => {
                        const activeCheck = activeChecks.some(check => check.type === type);

                        return (
                            <Button
                                key={`check-${index}`}
                                variant={activeCheck ? 'contained' : 'outlined'}
                                color={activeCheck ? 'warning' : 'normal'}
                                onClick={() => handleStartCheck(type)}
                                startIcon={type === 'pretrips' ? <FireTruckIcon /> : <ChecklistIcon />}
                                sx={{width: {xs: '100%', sm: 'auto'}}}
                            >
                                {activeCheck ? 'Continue' : 'Start'} {startCase(type)} Check
                            </Button>
                        );
                    })}
                </Stack>
            )}

            {hasFeature('tasks') && <TasksGrid sx={{mt: 3}} />}

            <RecordsGrid sx={{mt: 3}} />
        </Box>
    );
};