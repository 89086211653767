import React, {useState, useEffect, useCallback, useContext} from 'react';
import {GRID_TREE_DATA_GROUPING_FIELD, GRID_CHECKBOX_SELECTION_COL_DEF} from '@mui/x-data-grid-pro';
import {useSnackbar} from 'notistack';
import {Box, Stack, useMediaQuery, Button, Tooltip, Menu, Divider, MenuItem, ListItemIcon, ListItemText} from '@mui/material';
import {collection, onSnapshot, query, orderBy, where} from 'firebase/firestore';
import {useTheme} from '@mui/material/styles';
import {get, capitalize, difference} from 'lodash';
import moment from 'moment';
import {useForm, FormProvider, useFormContext} from 'react-hook-form';
import SchoolIcon from '@mui/icons-material/School';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PasswordIcon from '@mui/icons-material/Password';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import CakeIcon from '@mui/icons-material/Cake';
import {FirefighterRanks, Ranks} from '@embertracking/common';

import {db} from '-/firebase';
import {UserContext} from '-/contexts/User';
import {StationsContext} from '-/contexts/Stations';
import {SettingsContext} from '-/contexts/Settings';

import useSkills from '-/hooks/useSkills';

import {SkillStages, processRawDoc, hasPermission} from '-/data/utils';

import AutocompleteField from '-/form/AutocompleteField';

import MemberTraining from '-/dialogs/MemberTraining';

import {TrainingGrid, renderTrainingCell, renderTrainingLevelCell} from './matrix/Qualification';

const OptionsButton = () => {
    const {setValue, watch} = useFormContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const {currentUser} = useContext(UserContext);
    const {usesJIBC} = useContext(SettingsContext);

    const toggleFilter = watch('toggleFilter');

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = useCallback((e, value) => {
        if (!anchorEl) {
            setAnchorEl(e.currentTarget);
        }

        let newToggleFilter = toggleFilter || [];

        if (newToggleFilter.includes(value)) {
            newToggleFilter = newToggleFilter.filter(filter => filter !== value);
        } else {
            newToggleFilter.push(value);
        }
        
        setValue('toggleFilter', newToggleFilter.filter(Boolean));
    }, [setValue, toggleFilter, anchorEl]);

    let options = [
        {
            value: 'QUALIFICATIONS',
            label: 'Only Show Qualifications',
            tooltip: 'Show only the top level qualifications and not the individual skills',
            icon: <SchoolIcon fontSize="small" />
        },
        {
            value: 'SHOW_ALL_SKILLS',
            label: 'Show All Skills',
            tooltip: 'Show all skills, even if no members has completed them',
            disabled: toggleFilter.includes('QUALIFICATIONS'),
            icon: <VisibilityIcon fontSize="small" />
        },
        {
            value: 'GROUP_BY_RANK',
            label: 'Group by Rank',
            tooltip: 'Group members by their rank',
            icon: <Grid3x3Icon fontSize="small" />
        },
        {
            value: 'SELECTION',
            label: 'Show Selection',
            icon: <CheckBoxIcon fontSize="small" />
        }
    ];

    if (hasPermission(currentUser, 'users.write')) {
        options = [
            ...options,
            {divider: true},
            {
                value: 'EMAIL',
                label: 'Show Email',
                icon: <Grid3x3Icon fontSize="small" />
            },
            {
                value: 'DOB',
                label: 'Show Date of Birth',
                icon: <CakeIcon fontSize="small" />
            }
        ];

        if (usesJIBC) {
            options = [
                ...options,
                {
                    value: 'JIBC_NUMBER',
                    label: 'Show JIBC #',
                    icon: <Grid3x3Icon fontSize="small" />
                },
                {
                    value: 'JIBC_PASSWORDS',
                    label: 'Show JIBC Exam Passwords',
                    icon: <PasswordIcon fontSize="small" />
                }
            ];
        }
    }

    return (
        <>
            <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
            >
                Options
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                sx={{
                    '& .Mui-selected .MuiListItemText-primary': {
                        fontWeight: 'bold'
                    }
                }}
            >
                {options.map((option, index) => {
                    const {divider = false, disabled = false, value, label, tooltip} = option;

                    if (divider)  {
                        return <Divider key={`options-menu-dividier-${index}`} />;
                    }

                    const selected = toggleFilter.includes(value);

                    return (
                        <Tooltip title={tooltip} key={`option-menu-${value}`}>
                            <MenuItem key={value} disabled={disabled} selected={selected} onClick={e => handleClick(e, value)}>
                                <ListItemIcon>
                                    {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                </ListItemIcon>
                                <ListItemText primary={label} />
                            </MenuItem>
                        </Tooltip>
                    );
                })}
            </Menu>
        </>
    );
};

const TrainingMatrix = () => {
    const [firstLoading, setFirstLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [docs, setDocs] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {role} = currentUser || {};
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const canViewDialog = hasPermission(currentUser, 'users.write') || role === 'ADMINISTRATOR';
    const {stations} = useContext(StationsContext);

    const methods = useForm({
        defaultValues: {
            textFilter: null,
            toggleFilter: ['SHOW_ALL_SKILLS', 'GROUP_BY_RANK']
        },
        mode: 'onChange'
    });
    const {watch} = methods;
    
    const textFilter = watch('textFilter');
    const toggleFilter = watch('toggleFilter');

    const onlyQualifications = (toggleFilter || []).includes('QUALIFICATIONS');
    const showAllSkills = (toggleFilter || []).includes('SHOW_ALL_SKILLS');
    const groupByRank = (toggleFilter || []).includes('GROUP_BY_RANK');
    const showSelection = (toggleFilter || []).includes('SELECTION');
    const showJIBC = (toggleFilter || []).includes('JIBC_NUMBER');
    const showJIBCPassword = (toggleFilter || []).includes('JIBC_PASSWORDS');
    const showDOB = (toggleFilter || []).includes('DOB');
    const showEmail = (toggleFilter || []).includes('EMAIL');

    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const {skills: allSkills} = useSkills();

    const handleEmail = useCallback(() => {
        const selected = docs.filter(doc => rowSelectionModel.includes(doc.id));
        const emails = selected.map(doc => doc.email);

        window.open(`mailto:${emails.join(',')}`);
    }, [docs, rowSelectionModel]);

    const handleMessage = useCallback(() => {
        const selected = docs.filter(doc => rowSelectionModel.includes(doc.id));
        const numbers = selected.map(doc => doc.phone).filter(Boolean);

        window.open(`sms:/open?addresses=${numbers.join(',')}`);
    }, [docs, rowSelectionModel]);

    const handleCellClick = useCallback((params, e) => {
        const {field: rawField, row: member, rowNode} = params;
        const {uid} = member || {};
        const {type} = rowNode || {};
        const isCheck = rawField === '__check__';

        if (rawField && (['jibc', 'dob', 'email'].includes(rawField) || rawField.match('jibcPassword'))) {
            return;
        }

        if (!canViewDialog) {
            return;
        }

        if (isCheck) {
            const {id, children} = rowNode;

            if (type === 'group') {
                if (rowSelectionModel.includes(id)) {
                    setRowSelectionModel(difference(rowSelectionModel, [...children, id]));
                } else {
                    setRowSelectionModel([...rowSelectionModel, ...children, id]);
                }
            } else {
                if (rowSelectionModel.includes(id)) {
                    setRowSelectionModel(difference(rowSelectionModel, [id]));
                } else {
                    setRowSelectionModel([...rowSelectionModel, id]);
                }
            }

            return;
        }

        if (!uid) {
            return;
        }

        const splitField = rawField.split(',');
        const isLevel = splitField.length === 1;
        
        let field = splitField[splitField.length - 1];
        if (field === GRID_TREE_DATA_GROUPING_FIELD) {
            const {metaKey} = e;

            if (metaKey) {
                window.open(`/users/${uid}`);
                return;
            }

            window.location.href = `/users/${uid}`;
            
            return;
        }

        if (isLevel) {
            field = `skills.${field}`;
        }

        setDialogOpen({
            field,
            isLevel,
            member
        });
    }, [canViewDialog, rowSelectionModel]);

    useEffect(() => {
        let isSubscribed = true;
    
        const ref = collection(db, 'users');
        const q = query(ref, where('role', 'in', FirefighterRanks), orderBy('role', 'asc'));
        const snapshot = onSnapshot(q, snapshot => {
            let docs = [];

            let newAvailableFilters = [
                ...stations.map(station => {
                    return {
                        type: 'station',
                        value: station.id,
                        label: station.name
                    };
                }),
                ...FirefighterRanks.map(rank => {
                    return {
                        type: 'rank',
                        value: rank,
                        label: Ranks[rank]
                    };
                }),

                ...Object.keys(allSkills).reduce((result, level) => {
                    const {label} = allSkills[level];

                    result.push({
                        type: 'level',
                        value: level,
                        label: label
                    });

                    return result;
                }, []),
                ...Object.keys(allSkills).reduce((result, level) => {
                    const {label, skills = [], qualification} = allSkills[level];

                    if (qualification !== false) {
                        for (const [key, value] of Object.entries(skills)) {
                            const {label: skillLabel} = typeof value === 'string' ? {label: value} : value;

                            result.push({
                                type: 'skill',
                                value: `skills.${level}.${key}`,
                                label: `${label}: ${skillLabel}`
                            });
                        }
                    }

                    return result;
                }, []),

                {
                    type: 'status',
                    value: 'EXPIRING',
                    label: 'Expiring within 12 months'
                },

                ...Object.keys(SkillStages).map(stage => {
                    const {label} = SkillStages[stage];
                    return {
                        type: 'status',
                        value: stage,
                        label
                    };
                })
            ];
            
            snapshot.forEach(doc => {
                const data = processRawDoc(doc);
                const {uid, fullName, email, role} = data;
                const key = fullName;

                docs.push({
                    id: uid,
                    uid,
                    ...data,
                    path: groupByRank ? `${role ? Ranks[role] : 'No Rank'}/${key}` : doc.id
                });

                newAvailableFilters.push({
                    type: 'user',
                    label: fullName || email,
                    value: uid
                });
            });

            if (isSubscribed) {
                setDocs(
                    docs
                        .filter(doc => doc.archived !== true)
                        .sort((a, b) => {
                            return FirefighterRanks.indexOf(a.role) - FirefighterRanks.indexOf(b.role);
                        })
                );

                if (firstLoading) {
                    setFirstLoading(false);
                }

                setAvailableFilters(newAvailableFilters);

                setDialogOpen(existing => {
                    if (!existing) {
                        return false;
                    }

                    const uid = get(existing, 'member.uid');
                    const member = uid && docs.find(doc => doc.id === uid);

                    return {
                        ...existing,
                        member
                    };
                });
            }
        });
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, firstLoading, groupByRank]);

    useEffect(() => {
        let columns = [];

        const columnStatusFilters = (textFilter || []).filter(filter => filter.type === 'status');
        const columnLevelFilters = (textFilter || []).filter(filter => filter.type === 'level');
        let columnSkillFilters = (textFilter || []).filter(filter => filter.type === 'skill');

        const hasColumnFilters = columnLevelFilters.length > 0 || columnSkillFilters.length > 0;

        for (const [level, value] of Object.entries(allSkills)) {
            const {label: headerName, shortLabel: shortHeaderName, width = 105, skills = [], qualification = false} = value;
            const alwaysShowLevel = columnLevelFilters.length > 0 && columnLevelFilters.some(filter => filter.value === level);

            let levelColumns = onlyQualifications ? [] : Object.entries(skills).reduce((result, [key, value]) => {
                const field = `skills.${level}.${key}`;
                let valueField = field;
                const skillIsFiltered = columnSkillFilters.some(filter => filter.value === field);
                
                if ((alwaysShowLevel || !hasColumnFilters) || skillIsFiltered) {
                    let {label: headerName, width = 120, skill} = typeof value === 'string' ? {label: value} : value;

                    if (skill) {
                        valueField = `skills.${skill}`;

                        const [, level, skillKey] = valueField.split('.');
                        headerName = get(allSkills, `${level}.skills.${skillKey}`);
                    }

                    result.push({
                        field: [field, valueField].filter(Boolean).join(','),
                        headerName,
                        width,
                        headerAlign: 'center',
                        valueGetter: (value, row) => {
                            const {uid} = row;
                            const result = get(row, valueField, uid && 'INCOMPLETE');

                            if (typeof result === 'object') {
                                const {status, expiryDate} = result || {};
                                if (expiryDate) {
                                    return expiryDate;
                                }

                                return status;
                            }

                            return result;
                        },
                        renderCell: renderTrainingCell(valueField)
                    });
                }

                return result;
            }, []);

            const extra = get(allSkills, `${level}.extra`, false);

            if (qualification !== false || levelColumns.length || (onlyQualifications && !extra)) {
                columns.push({
                    field: level,
                    headerName: shortHeaderName || headerName,
                    width: isSmall ? 80 : width,
                    headerAlign: 'center',
                    headerClassName: 'style-training-level',
                    cellClassName: 'style-training-level',
                    renderCell: renderTrainingLevelCell(level),
                    valueGetter: (value, row) => {
                        const {status, completedDate: rawCompletedDate} = get(row, `skills.${level}`, {});

                        if (level === 'exterior' || level === 'interior') {
                            const hasInterior = get(row, 'skills.interior.status') === 'COMPLETE';
                            const hasFullService = get(row, 'skills.fullService.status') === 'COMPLETE';

                            if (hasFullService || (hasInterior && row !== 'skills.interior')) {
                                return 'COMPLETE';
                            }
                        }

                        if (!status && rawCompletedDate) {
                            return 'COMPLETE';
                        }

                        return status || 'INCOMPLETE';
                    }
                });

                columns.push(...levelColumns);
            }
        }

        const rowFilters = (textFilter || []).filter(filter => !['level', 'skill'].includes(filter.type));
        let filtered = docs;

        if (rowFilters.length) {
            const rowFilterableFilters = rowFilters.filter(filter => ['user', 'station', 'rank'].includes(filter.type));

            if (rowFilterableFilters.length) {
                filtered = filtered.filter(doc => {
                    let match = false;
                    
                    for (const filter of rowFilterableFilters) {
                        const {type, value} = filter;

                        if (type === 'user') {
                            if (doc.uid === value) {
                                match = true;
                            }
                        } else if (type === 'station') {
                            if (doc.station === value) {
                                match = true;
                            }
                        } else if (type === 'rank') {
                            if (doc.role === value) {
                                match = true;
                            }
                        }
                    }

                    return match;
                });
            }

            if (columnStatusFilters.length) {
                filtered = filtered.filter(doc => {
                    return columns.some(column => {
                        const {field: rawField} = column;

                        const splitField = rawField.split(',');
                        const isLevel = splitField.length === 1;

                        if (isLevel) {
                            return false;
                        }

                        const field = splitField[splitField.length - 1];
                        const value = get(doc, field) || {};
                        const {expiryDate, status} = typeof value === 'string' ? {status: value} : value;

                        const result = columnStatusFilters.some(filter => {
                            const {value: stage} = filter;

                            if (stage === 'INCOMPLETE') {
                                return !status || status !== 'COMPLETE';
                            } else if (stage === 'EXPIRING') {
                                return expiryDate && moment(expiryDate.toDate()).isBefore(moment().add(12, 'month'));
                            }

                            return status === stage;
                        });

                        return result;
                    });
                });

                // show the column only if there are filters that match
                columns = columns.filter(column => {
                    const {field: rawField} = column;

                    const splitField = rawField.split(',');
                    const isLevel = splitField.length === 1;

                    if (isLevel) {
                        return true;
                    }

                    const field = splitField[splitField.length - 1];

                    // get all skill values for this column
                    const values = filtered.map(doc => {
                        const value = get(doc, field) || {};
                        const {expiryDate, status} = typeof value === 'string' ? {status: value} : value;
                        return {expiryDate, status};
                    });

                    return columnStatusFilters.some(filter => {
                        const {value: stage} = filter;

                        if (stage === 'INCOMPLETE') {
                            return values.some(value => !value.status || value.status !== 'COMPLETE');
                        } else if (stage === 'EXPIRING') {
                            return values.some(value => {
                                const {expiryDate} = value;
                                return expiryDate && moment(expiryDate.toDate()).isBefore(moment().add(12, 'month'));
                            });
                        }

                        return values.some(value => value.status === stage);
                    });
                });

                // show the level only if there are skills under that level
                const columnSkills = columns.reduce((result, column) => {
                    const {field: rawField} = column;

                    const splitField = rawField.split(',');
                    const isLevel = splitField.length === 1;

                    if (isLevel) {
                        return result;
                    }

                    const [, field] = splitField;

                    result.push(field);

                    return result;
                }, []);

                columns = columns.filter(column => {
                    const {field: rawField} = column;

                    const splitField = rawField.split(',');
                    const isLevel = splitField.length === 1;

                    if (!isLevel) {
                        return true;
                    }

                    return columnSkills.some(skill => skill.match(rawField));
                });
            }
        }

        setFilteredDocs(filtered);

        if (showDOB) {
            columns.unshift({
                field: 'dob',
                headerName: 'DOB',
                headerAlign: 'center',
                align: 'center',
                cellClassName: params => {
                    const {rowNode} = params;
                    const {depth = 0} = rowNode;

                    if (depth === 0) {
                        return;
                    }

                    return 'style-extra-info';
                },
                width: 120,
                valueGetter: (value, row) => {
                    const {dob} = row;
                    if (!dob) {
                        return '';
                    }

                    return moment(dob.toDate()).format('YYYY-MM-DD');
                }
            });
        }

        if (showEmail) {
            columns.unshift({
                field: 'email',
                headerName: 'Email',
                headerAlign: 'center',
                cellClassName: params => {
                    const {rowNode} = params;
                    const {depth = 0} = rowNode;

                    if (depth === 0) {
                        return;
                    }

                    return 'style-extra-info';
                },
                width: 250,
                valueGetter: (value, row) => {
                    const {email} = row;

                    return email;
                }
            });
        }

        if (showJIBCPassword) {
            columns.unshift({
                field: 'jibcPassword2',
                headerName: 'Password 2',
                headerAlign: 'center',
                align: 'center',
                cellClassName: params => {
                    const {rowNode} = params;
                    const {depth = 0} = rowNode;

                    if (depth === 0) {
                        return;
                    }

                    return 'style-extra-info';
                },
                valueGetter: (value, row) => {
                    const {dob} = row;
                    if (!dob) {
                        return '';
                    }

                    return moment(dob.toDate()).format('YYYYMMMDD');
                }
            });

            columns.unshift({
                field: 'jibcPassword1',
                headerName: 'Password 1',
                headerAlign: 'center',
                align: 'center',
                cellClassName: params => {
                    const {rowNode} = params;
                    const {depth = 0} = rowNode;

                    if (depth === 0) {
                        return;
                    }

                    return 'style-extra-info';
                },
                valueGetter: (value, row) => {
                    const {dob} = row;
                    if (!dob) {
                        return '';
                    }

                    return moment(dob.toDate()).format('YYYYMMDD');
                }
            });
        }

        if (showJIBC) {
            columns.unshift({
                field: 'jibcNumber',
                headerName: 'JIBC #',
                headerAlign: 'center',
                align: 'center',
                cellClassName: params => {
                    const {rowNode} = params;
                    const {depth = 0} = rowNode;

                    if (depth === 0) {
                        return;
                    }

                    return 'style-extra-info';
                },
                valueGetter: (value, row) => {
                    const {jibcNumber} = row;
                    return jibcNumber && jibcNumber.toUpperCase();
                }
            });
        }

        // check if the field has a value if showAllSkills is false
        if (showAllSkills === false) {
            columns = columns.filter(column => {
                const {field} = column;

                if (field.match('skills')) {
                    const {field: rawField} = column;
                    const splitField = rawField.split(',');
                    const isLevel = splitField.length === 1;

                    if (isLevel) {
                        return true;
                    }

                    const field = splitField[splitField.length - 1];
                    const hasValue = filtered.some(doc => {
                        const value = get(doc, field);
                        return value && value !== 'INCOMPLETE';
                    });

                    return hasValue;
                }

                return true;
            });
        }

        // Filter out any levels that dont have skills visible
        if (!onlyQualifications) {
            columns = columns.filter(column => {
                const {field} = column;

                if (!field.match('skills') && !field.match('dob') && !field.match('jibc') && !field.match('email')) {
                    const {field: rawField} = column;
                    const splitField = rawField.split(',');
                    const isLevel = splitField.length === 1;

                    if (!isLevel) {
                        return true;
                    }

                    const field = splitField[splitField.length - 1];
                    const hasSkills = columns.some(column => {
                        if (column.field !== rawField && column.field.match(field)) {
                            return true;
                        }

                        return false;
                    });

                    return hasSkills;
                }

                return true;
            });
        }

        setColumns(columns);
    }, [docs, isSmall, showJIBC, showJIBCPassword, showEmail, showDOB, textFilter, onlyQualifications, showAllSkills]);

    return (
        <FormProvider {...methods}>
            <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                {canViewDialog && !!dialogOpen && (
                    <MemberTraining editable={hasPermission(currentUser, 'users.write')} open={!!dialogOpen} {...dialogOpen} onClose={() => setDialogOpen(false)} />
                )}

                {canViewDialog && (
                    <Stack direction="row" spacing={1} alignItems="stretch" sx={{mb: 1}}>
                        <AutocompleteField
                            label="Filter by Station, Rank, Level, Member or Status"
                            name="textFilter"
                            multiple
                            options={availableFilters}
                            filterSelectedOptions
                            disableCloseOnSelect
                            sx={{flex: 1, mr: 1}}
                            groupBy={option => {
                                const {type} = option;
                                return capitalize(type);
                            }}
                        />

                        <OptionsButton />

                        {rowSelectionModel.length > 0 && (
                            <>
                                <Button variant="contained" onClick={handleEmail}>Email</Button>
                                <Button variant="contained" onClick={handleMessage}>Message</Button>
                            </>
                        )}
                    </Stack>
                )}

                <Box sx={{flex: 1, position: 'relative'}}>
                    <Box sx={{inset: 0, position: 'absolute'}}>
                        <TrainingGrid
                            initialState={{
                                pinnedColumns: {
                                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_TREE_DATA_GROUPING_FIELD]
                                }
                            }}
                            experimentalFeatures={{
                                rowPinning: true
                            }}
                            checkboxSelection={showSelection}
                            treeData
                            getTreeDataPath={row => {
                                return row.path.split('/');
                            }}
                            groupingColDef={{
                                headerName: `${filteredDocs.length} members`,
                                width: isSmall ? 120 : 220,
                                hideable: false,
                                sortable: true,
                                valueGetter: (value, row) => {
                                    const {firstName, lastName, email} = row || {};

                                    return [lastName, firstName].filter(Boolean).join(', ') || email;
                                },
                                valueFormatter: (value, row) => {
                                    const {fullName, firstName, lastName, email} = row || {};

                                    if (isSmall) {
                                        const parts = [
                                            ...(firstName ? [firstName[0]] : []),
                                            lastName
                                        ].filter(Boolean);

                                        if (parts.length) {
                                            return parts.join('. ');
                                        }
                                    }
                    
                                    return fullName || email;
                                },
                                cellClassName: params => {
                                    const {rowNode} = params;
                                    const {depth = 0} = rowNode;
                                    
                                    if (depth === 0 && groupByRank) {
                                        return 'grouping-cell-header';
                                    }

                                    return 'grouping-cell';
                                }
                            }}
                            onCellClick={handleCellClick}
                            rowSelectionModel={rowSelectionModel}
                            loading={firstLoading}
                            localeText={{noRowsLabel: firstLoading ? 'Loading qualifications' : 'No qualifications'}}
                            rows={filteredDocs}
                            columns={filteredDocs.length ? columns : []}
                            defaultGroupingExpansionDepth={-1}
                            className={onlyQualifications && 'only-qualifications'}
                        />
                    </Box>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default TrainingMatrix;