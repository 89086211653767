import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Box, Tooltip, Stack, Typography, Divider, IconButton, Button} from '@mui/material';
import {DataGridPro, useGridApiRef} from '@mui/x-data-grid-pro';
import {useParams, useNavigate} from 'react-router-dom';
import {useForm, useFieldArray, useFormContext, FormProvider} from 'react-hook-form';
import useDebouncedEffect from 'use-debounced-effect';
import {useSnackbar} from 'notistack';
import {collection, writeBatch, getDoc, getDocs, query, where, orderBy, setDoc, updateDoc, doc, limit, deleteField} from 'firebase/firestore';
import {startCase} from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import {useConfirm} from 'material-ui-confirm';

import {UserContext} from '-/contexts/User';
import {db} from '-/firebase';

import {populateUsers, processRawDoc} from '-/data/utils';

import TextField from '-/form/TextField';

const Checks = props => {
    const apiRef = useGridApiRef();

    const {index, loading, editing, onConvertToSection} = props;
    const {control, setValue} = useFormContext();
    const {currentUser} = useContext(UserContext);
    const name = `sections[${index}].checks`;
    const {fields, insert, swap, remove} = useFieldArray({
        control,
        name
    });

    const isSuperAdmin = currentUser && currentUser.superadmin === true;
    const canDeleteRow = fields.length > 1;

    const columns = [
        {
            field: 'text',
            flex: 1,
            sortable: false,
            editable: editing,
            renderCell: params => {
                const {value} = params;

                return (
                    <Box sx={{p: 1.5}}>
                        {value}
                    </Box>
                );
            }
        }
    ];

    if (editing) {
        columns.push({
            field: 'delete',
            headerName: '',
            type: 'actions',
            editable: false,
            width: isSuperAdmin ? 150 : 100,
            renderCell: params => {
                const {id} = params;
                const index = fields.findIndex(field => field.id === id);

                return (
                    <Stack direction="row" spacing={1} sx={{p: 1}}>
                        {isSuperAdmin && (
                            <IconButton onClick={() => handleConvertToSection(index)}>
                                <MoveDownIcon />
                            </IconButton>
                        )}
                        <IconButton onClick={() => handleRowAdd(index)}>
                            <AddIcon />
                        </IconButton>
                        <IconButton disabled={!canDeleteRow} onClick={() => handleRowRemove(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                );
            },
            disableClickEventBubbling: true
        });
    }

    const handleRowUpdate = useCallback(async data => {
        const checkIndex = fields.findIndex(field => field.id === data.id);

        setValue(`${name}[${checkIndex}].text`, data.text);

        return data;
    }, [fields]);
    
    const handleRowAdd = useCallback(async index => {
        insert(index + 1, {text: ''});
    }, []);

    const handleConvertToSection = useCallback(async fieldIndex => {
        const {text: title} = fields[fieldIndex];

        const updatedSection = {
            checks: fields.slice(0, fieldIndex)
        };

        const newSection = {
            title,
            checks: fields.slice(fieldIndex + 1)
        };

        if (onConvertToSection) {
            onConvertToSection(index, fieldIndex, updatedSection, newSection);
        }
    }, [onConvertToSection, fields]);

    const handleRowRemove = useCallback(async index => {
        if (!canDeleteRow) {
            return;
        }

        remove(index);
    }, [canDeleteRow]);

    const handleRowOrderChange = useCallback(change => {
        const {oldIndex, targetIndex} = change;
        swap(oldIndex, targetIndex);
    }, []);

    return (
        <Box sx={{width: '100%'}}>
            <Box style={{display: 'flex', flexDirection: 'column', minHeight: !fields || fields.length === 0 || loading ? 100 : 'auto'}}>
                <DataGridPro
                    apiRef={apiRef}
                    rowReordering={editing}
                    onRowOrderChange={handleRowOrderChange}
                    hideFooter
                    loading={loading}
                    autoHeight
                    rows={fields}
                    columns={columns}
                    getRowHeight={() => 'auto'}
                    editMode="row"
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    disableColumnResize
                    sx={{
                        '& .MuiDataGrid-topContainer': {
                            display: 'none'
                        }
                    }}
                    processRowUpdate={handleRowUpdate}
                    experimentalFeatures={{newEditingApi: true}}
                />

                {fields.length === 0 && !loading && (
                    <Stack sx={{mt: 2}} direction="row" justifyContent="flex-end" alignItems="center">
                        <Button startIcon={<AddIcon />} onClick={() => handleRowAdd(0)}>Add Check</Button>
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

const Sections = props => {
    const {loading, saving, editing} = props;
    const {control} = useFormContext();
    const {fields, insert, swap, update, remove} = useFieldArray({
        control,
        name: 'sections'
    });

    const handleSectionAdd = useCallback(async index => {
        insert(index + 1, {checks: [{text: ''}]});
    }, []);

    const handleSectionRemove = useCallback(async index => {
        remove(index);
    }, []);

    const handleSectionUp = useCallback(async index => {
        if (index > 0) {
            swap(index, index - 1);
        }
    }, []);

    const handleSectionDown = useCallback(async index => {
        if (index < fields.length - 1) {
            swap(index, index + 1);
        }
    }, []);

    const handleConvertToSection = useCallback(async (index, fieldIndex, updatedSection, newSection) => {
        const {title} = fields[index] || {};

        update(index, {
            title,
            ...updatedSection
        });

        insert(index + 1, newSection);
    }, [fields]);

    return fields.map((section, index) => {
        const {id, title} = section;
        const canMoveUp = index > 0;
        const canMoveDown = index < fields.length - 1;

        return (
            <Stack spacing={2} key={`section-${id}`} sx={{mb: 2}}>
                <Stack direction="row" spacing={1}sx={{mb: 2}}>
                    {editing && (canMoveUp || canMoveDown) && (
                        <Stack direction="column" spacing={1}>
                            {canMoveUp && (
                                <Tooltip title="Move section up">
                                    <IconButton onClick={() => handleSectionUp(index)}>
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {canMoveDown && (
                                <Tooltip title="Move section down">
                                    <IconButton onClick={() => handleSectionDown(index)}>
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                    <Stack direction="column" spacing={1} sx={{flex: 1}}>
                        {title && !editing && <Typography variant="h6">{title}</Typography>}
                        {editing && (
                            <TextField
                                fullWidth
                                label="Section Title"
                                name={`sections[${index}].title`}
                                placeholder="Compartment 2"
                                sx={{mb: 1}}
                            />
                        )}

                        <Checks index={index} onConvertToSection={handleConvertToSection} {...props} />
        
                        {editing && (
                            <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                {fields.length > 1 && (
                                    <Button
                                        onClick={() => handleSectionRemove(index)}
                                        disabled={saving || loading}
                                        startIcon={<DeleteIcon />}
                                        size="small"
                                    >
                                        Remove Section
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    onClick={() => handleSectionAdd(index)}
                                    disabled={saving || loading}
                                    startIcon={<AddIcon />}
                                    size="small"
                                >
                                    Add Section
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Stack>

                {index < fields.length - 1 && <Divider />}
            </Stack>
        );
    });
};

const EditChecks = () => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {id: uid, type = 'weekly', '*': path = ''} = useParams();
    const confirm = useConfirm();

    const methods = useForm({
        defaultValues: {
            sections: []
        },
        mode: 'onChange'
    });
    const {handleSubmit, getValues, watch, reset, formState} = methods;
    const {dirtyFields, isDirty} = formState;

    const allValues = watch();

    let collectionKey = 'apparatus';
    if (path) {
        const [firstKey] = path.split('/');
        if (firstKey) {
            collectionKey = firstKey;
        }
    }

    let isSubscribed = true;

    const fetch = useCallback(async() => {
        try {
            const ref = collection(db, 'checks');
            const q = query(ref, where(collectionKey, '==', uid), where('type', '==', type), orderBy('createdAt', 'desc'), limit(1));
            const result = await getDocs(q);

            let record;

            let raw = result.docs[0];
            if (raw) {
                record = await processRawDoc(raw);
                record = await populateUsers(db, record);
            }

            if (isSubscribed) {
                const {sections = [], checks = []} = record || {};
                let data = {sections};
                if (checks.length) {
                    data = {sections: [{checks}]};
                }

                if (!record || (!sections.length && !checks.length)) {
                    handleAddVersion();
                } else {
                    reset(data);
                }
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            console.warn(e);
        }

        if (isSubscribed) {
            setLoading(false);
        }
    }, [enqueueSnackbar, db, uid, type, isSubscribed, collectionKey]);

    useEffect(() => {
        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, uid]);

    const handleSave = useCallback(async () => {
        const {sections} = getValues();

        try {
            setSaving(true);

            const filteredSections = sections.filter(section => {
                const {checks} = section;
                return checks.some(check => check.text);
            });

            const docRef = doc(db, 'checks', uid);
            await setDoc(docRef, {
                [collectionKey]: uid,
                type,
                sections: filteredSections,
                createdAt: new Date(),
                user: currentUser.uid
            });

            setSaving(false);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});

            setSaving(false);
        }
    }, [getValues, collectionKey, type, uid, currentUser]);

    useDebouncedEffect(async () => {
        console.log({editing, isDirty})
        if (editing && isDirty) {
            await handleSave();
        }
    }, 2000, [dirtyFields, editing, allValues, collectionKey, type, uid, currentUser, handleSave]);

    const onSubmit = useCallback(async () => {
        try {
            setSaving(true);

            await handleSave();

            try {
                const parentDocRef = doc(db, path.split('/').slice(0, -2).join('/'));
                const parentDoc = await getDoc(parentDocRef);
                if (parentDoc.exists()) {
                    await updateDoc(parentDocRef, {
                        [`checks.${type}`]: true
                    });
                }
            } catch(e) {
                console.warn(`Error updating parent document: ${e.message}`);
                console.warn(e);
            }

            try {
                const q = query(collection(db, 'checks'), where(collectionKey, '==', uid), where('type', '==', type));
                const result = await getDocs(q);

                const oldChecks = result.docs.filter(doc => doc.id !== uid);
                if (oldChecks.length) {
                    console.warn(`Deleting ${oldChecks.length} old checks`);

                    const batch = writeBatch(db);

                    oldChecks.forEach(doc => {
                        batch.delete(doc.ref);
                    });

                    await batch.commit();
                }
            } catch(e) {
                console.warn(`Error deleting old checks: ${e.message}`);
                console.warn(e);
            }

            setSaving(false);
            setEditing(false);

            await fetch();
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});

            setSaving(false);
        }
    }, [db, currentUser, enqueueSnackbar, uid, fetch, collectionKey]);

    const handleCancel = useCallback(async() => {
        setEditing(false);

        reset();

        navigate(-1);
    }, []);

    const handleAddVersion = useCallback(async() => {
        setEditing(true);

        const {sections} = getValues();
        if (sections.length === 0) {
            reset({
                sections: [{
                    checks: [{text: ''}]
                }]
            });
        }
    }, []);

    const onDelete = useCallback(async() => {
        try {
            setSaving(true);

            await updateDoc(doc(db, 'apparatus', uid), {
                [`checks.${type}`]: deleteField()
            });

            setSaving(false);

            navigate(`/apparatus/${uid}`);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});

            setSaving(false);
        }
    }, []);

    const handleDelete = useCallback(async() => {
        const {confirmed} = await confirm({
            description: 'Are you sure you want to delete this check? This cannot be undone.',
            confirmationText: 'Delete Check'
        });
      
        if (confirmed) {
            onDelete();
        }
    }, []);

    return (
        <FormProvider {...methods}>
            <Stack direction="row" alignItems="center" sx={{mb: 1}}>
                <Typography variant="h5" sx={{flex: 1}}>Edit {startCase(type)} Check</Typography>
                
                <Stack direction="row" spacing={1}>
                    {editing ? (
                        <>
                            <Button startIcon={<DoDisturbIcon />} onClick={handleCancel}>Cancel</Button>
                            <Button loading={saving} startIcon={<SaveIcon />} variant="contained" onClick={handleSubmit(onSubmit)}>Save Changes</Button>
                        </>
                    ) : (
                        <>
                            <Button startIcon={<DeleteIcon />} color="danger" onClick={handleDelete}>Delete</Button>
                            <Button startIcon={<EditIcon />} variant="contained" onClick={handleAddVersion}>Edit</Button>
                        </>
                    )}
                </Stack>
            </Stack>

            <Divider sx={{mb: 2}} />

            <Sections {...{loading, saving, editing}} />
        </FormProvider>
    );
};

export default EditChecks;